import React from "react";
import {ISupport} from "../../../interfaces/support.interface";

interface SupportProps {
    support: ISupport;
}

const Support: React.FC<SupportProps> = ({support}) => {

    return (
        <div>
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">Support Page</h3>
            <div className="ml-3">
                <p className='mb-2'><strong>Support Id:</strong> {support.id || ""}</p>
                <p className='mb-2'><strong>Name:</strong> {support.name || ""}</p>
                <p className='mb-2'><strong>Description:</strong> {support.description || ""}</p>
                <p className='mb-2'><strong>Entity Id:</strong> {support.entityId || ""}</p>
                <p className='mb-2'><strong>Entity Type:</strong> {support.entityType?.name || ""}</p>
                <p className='mb-2'><strong>Support Type:</strong> {support.supportType?.name || ""}</p>
                <br />
                <p className='mb-2'><strong>Active:</strong> {support.active ? 'Yes' : 'No'}</p>
                <p className='mb-2'><strong>Resolution:</strong> {support.resolution || ""}</p>
                <br />
                <p className="mt-3 mb-0 text-muted font-italic">Support Id: {support.id || ""}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Created by
                    Created
                    on {support.createdDate ? new Date(support.createdDate).toLocaleDateString() : "None"}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Modified by
                    Updated
                    on {support.modifiedDate ? new Date(support.modifiedDate).toLocaleDateString() : "None"}</p>
            </div>
        </div>
    );

};

export default Support;