import React from 'react';

const BusinessRulesHeader: React.FC = () => {
    return (
        <div className='mb-4'>
            <h4>Business Rules</h4>
            <p>By adding a business, you agree to the following rules:</p>
            <ul>
                <li>You cannot add any business or links to which you or someone you know would make a profit.</li>
                <li>Information submitted must be accurate and appropriate in nature. Submitting false or inappropriate
                    content will result in your account being locked.
                </li>
                <li>Business must offer fragrance-free environments or services that aid in fragrance-free living.</li>
                <li>Any user may edit a businesses details for accuracy or completeness.</li>
                <li>Only users that created the business or a moderator can delete a business. Please submit a support ticket to delete a business if needed.</li>
            </ul>
        </div>
    );
};

export default BusinessRulesHeader;