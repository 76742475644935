import React from 'react';


const HomeRulesHeader: React.FC = () => {


    return (
        <div className='mb-4'>
            <h4>Home Rules</h4>
            <p>By adding a home, you agree to the following rules:</p>
            <ul>
                <li>You cannot add any home or links to which you or someone you know would make a profit.</li>
                <li>Information submitted must be accurate and appropriate in nature. Submitting false or inappropriate
                    content will result in your account being locked.
                </li>
                <li>Homes must offer fragrance-free environments.</li>
                <li>Any user may edit a homes details for accuracy or completeness.</li>
                <li>Only users that created the home or a moderator can delete a home. Please submit a support ticket to
                    delete a home if needed.
                </li>
            </ul>
        </div>
    );

};

export default HomeRulesHeader;