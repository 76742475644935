import React from 'react';
import {useNavigate} from 'react-router-dom';
import BusinessesList from './components/BusinessList';
import apiRoutes from "../../config/apiRoutes";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {useAuth} from "../../contexts/authContext";

const BusinessesListPage: React.FC = () => {
    const navigate = useNavigate();
    const handleGoBack = () => navigate(-1);
    const { currentUser } = useAuth();

    const handleAddBusiness = () => {
        window.location.href = apiRoutes.BASE + '/businesses/0/edit';
    };

    if (currentUser) {
        return (
            <div>
                <HelmetEntityComponent title="Business List" entity="Businesses"/>
                <BusinessesList/>
                <button className="btn btn-success m-2" onClick={handleAddBusiness}>Add a Business</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        );
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Business List" entity="Businesses"/>
                <BusinessesList/>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
                <p>Please login to add or edit Businesses and Reviews.</p>
            </div>
        );
    }
};

export default BusinessesListPage;
