import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {
    IBusinessReview,
    IBusinessReviewForm,
    IBusinessReviewSubmit
} from '../../../interfaces/business.review.interface';
import apiRoutes from "../../../config/apiRoutes";
import authUser from "../../../services/auth.user";
import ReviewFormModal from "./ReviewFormModal";
import {toast} from 'react-toastify';
import BusinessReviewDisplay from "../BusinessReviewDisplay";
import {axiosRequest, handleRequestError} from "../../../services/axios.service";
import {HttpMethods} from "../../../routes/HttpMethods";


const BusinessReviewPage: React.FC = () => {
    const {businessId, id} = useParams<{ businessId: string; id: string }>();
    const [businessReview, setBusinessReview] = useState<IBusinessReview>();
    const authLevel = authUser(businessReview?.createdById || 0)
    const [showModal, setShowModal] = useState(false);
    const [reviewForm, setReviewForm] = useState<IBusinessReviewForm>({
        name: '',
        comment: '',
        rating: 5,
        businessEntity: Number(id)
    });
    const [formErrors, setFormErrors] = useState<{ name?: string; rating?: string }>({});
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const validateForm = () => {
        const errors: { name?: string; rating?: string } = {};
        if (!reviewForm.name) {
            errors.name = 'Name is required';
        }
        if (reviewForm.rating < 1 || reviewForm.rating > 5) {
            errors.rating = 'Rating must be between 1 and 5';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    function convertFormToSubmit(form: IBusinessReviewForm): IBusinessReviewSubmit {
        return {
            ...form,
            businessEntity: id !== undefined ? {'id': Number(id)} : null }
    }

    const handleAddReview = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        const updatedFormValues = convertFormToSubmit(reviewForm)
        axiosRequest(HttpMethods.PUT, apiRoutes.BUSINESSES + `/${businessId}/reviews/${id}`, "Business Review", updatedFormValues).then(response => {
            handleCloseModal();
            window.location.reload();
        }).catch(error => {
            handleCloseModal();
            console.error(error);
        });
    };

    const handleDelete = async () => {
        axiosRequest(HttpMethods.DELETE, apiRoutes.BUSINESSES + `/${businessId}/reviews/${id}`, "Business Review").then(response => {
            navigate(`/businesses/${businessId}`)
        }).catch(error => {
            handleRequestError(error, "Business Review");
        });
    };

    useEffect(() => {
        const fetchBusinessReview = async () => {
            try {
                const response = await axios.get(apiRoutes.BUSINESSES + `/${businessId}/reviews/${id}`);
                if (!response.data) {
                    toast.error("Failed to get business review.")
                    return
                }
                setReviewForm(response.data)
                setBusinessReview(response.data);
            } catch (error) {
                toast.error("Error getting Business Review Information")
                console.error('Error fetching business data:', error);
            }
        };

        fetchBusinessReview();
    }, [businessId, id]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        setReviewForm({...reviewForm, [name]: value});
    };

    if (!businessReview) {
        return <div>Loading business review...</div>;
    } else if (authLevel === 0) {
        return (
            <div>
                <BusinessReviewDisplay content={businessReview}/>

                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
                <p>Please login to add or edit Businesses and Reviews.</p></div>
        )
    } else if (authLevel >= 2) {
        return (
            <div>
                <ReviewFormModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    handleAddReview={handleAddReview}
                    reviewForm={reviewForm}
                    handleChange={handleChange}
                    formErrors={formErrors}
                />

                <BusinessReviewDisplay content={businessReview}/>

                <button className="btn btn-primary m-2" onClick={handleShowModal}>Edit this Review</button>
                <button className="btn btn-danger m-2" onClick={handleDelete}>Delete this Review</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        )
    } else {
        return (
            <div>
                <BusinessReviewDisplay content={businessReview}/>

                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        );
    }
};

export default BusinessReviewPage;