import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {IProductReview, IProductReviewForm, IProductReviewSubmit} from '../../../interfaces/product.review.interface';
import apiRoutes from "../../../config/apiRoutes";
import authUser from "../../../services/auth.user";
import ReviewFormModal from "./ReviewFormModal";
import {toast} from "react-toastify";
import ProductReviewDisplay from "../ProductReviewDisplay";
import {axiosRequest, handleRequestError} from "../../../services/axios.service";
import {HttpMethods} from "../../../routes/HttpMethods";


const ProductReviewPage: React.FC = () => {
    const {productId, id} = useParams<{ productId: string; id: string }>();
    const [productReview, setProductReview] = useState<IProductReview>();
    const authLevel = authUser(productReview?.createdById || 0)
    const [showModal, setShowModal] = useState(false);
    const [reviewForm, setReviewForm] = useState<IProductReviewForm>({
        name: '',
        comment: '',
        rating: 5,
        productEntity: Number(id)
    });
    const [formErrors, setFormErrors] = useState<{ name?: string; rating?: string }>({});
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const validateForm = () => {
        const errors: { name?: string; rating?: string } = {};
        if (!reviewForm.name) {
            errors.name = 'Name is required';
        }
        if (reviewForm.rating < 1 || reviewForm.rating > 5) {
            errors.rating = 'Rating must be between 1 and 5';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    function convertFormToSubmit(form: IProductReviewForm): IProductReviewSubmit {
        return {
            ...form,
            productEntity: id !== undefined ? {'id': Number(id)} : null }
    }

    const handleAddReview = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        const updatedFormValues = convertFormToSubmit(reviewForm)
        axiosRequest(HttpMethods.PUT, apiRoutes.PRODUCTS + `/${productId}/reviews/${id}`, "Product Review", updatedFormValues).then(response => {
            handleCloseModal();
            window.location.reload();
        }).catch(error => {
            handleCloseModal();
            console.error(error);
        });
    };

    const handleDelete = async () => {
        axiosRequest(HttpMethods.DELETE, apiRoutes.PRODUCTS + `/${productId}/reviews/${id}`, "Product Review").then(response => {
            navigate(`/products/${productId}`)
        }).catch(error => {
            handleRequestError(error, "Product Review")
        });
    };

    useEffect(() => {
        const fetchProductReview = async () => {
            try {
                const response = await axios.get(apiRoutes.PRODUCTS + `/${productId}/reviews/${id}`);
                if (!response.data) {
                    toast.error("Failed to get product review.")
                    return
                }
                setReviewForm(response.data)
                setProductReview(response.data);
            } catch (error) {
                toast.error("Error getting Product Review Information")
                console.error('Error fetching product data:', error);
            }
        };

        fetchProductReview();
    }, [productId, id]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        setReviewForm({...reviewForm, [name]: value});
    };

    if (!productReview) {
        return <div>Loading product review...</div>;
    } else if (authLevel === 0) {
        return (
            <div>
                <ProductReviewDisplay content={productReview}/>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
                <p>Please login to add or edit Products and Reviews.</p></div>
        )
    } else if (authLevel >= 2) {
        return (
            <div><ReviewFormModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    handleAddReview={handleAddReview}
                    reviewForm={reviewForm}
                    handleChange={handleChange}
                    formErrors={formErrors}
                />

                <ProductReviewDisplay content={productReview}/>

                <button className="btn btn-primary m-2" onClick={handleShowModal}>Edit this Review</button>
                <button className="btn btn-danger m-2" onClick={handleDelete}>Delete this Review</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        )
    } else {
        return (
            <div>
                <ProductReviewDisplay content={productReview}/>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        );
    }
};

export default ProductReviewPage;