import React from 'react';
import {FaStar, FaStarHalfAlt} from 'react-icons/fa';

interface StarProps {
    rating: number;
}

const StarRating: React.FC<StarProps> = ({rating}) => {
    const roundedRating = Math.min(Math.max(rating, 0), 5);
    const fullStars = Math.floor(roundedRating);
    const hasHalfStar = roundedRating % 1 >= 0.5;
    const fullStarArray = Array(fullStars).fill(null).map((_, index) => <FaStar key={index}/>);
    const halfStar = hasHalfStar ? <FaStarHalfAlt key="half-star"/> : null;

    return (
        <div>
            {fullStarArray}
            {halfStar}
        </div>
    );
};

export default StarRating;
