import React from "react";
import {IProductReview} from "../../interfaces/product.review.interface";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";

interface IProps {
    content: IProductReview;
}

const ProductReviewDisplay: React.FC<IProps> = ({ content }) => {
    return (
        <div>
            <HelmetEntityComponent title="Product Review View" entity="Product Review"/>
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">Product Review</h3>

            <h4 className='mb-2'>{content.name}</h4>
            <p className='mb-2'>Comment: {content.comment}</p>
            <p className='mb-2'><strong>Rating:</strong> {content.rating}</p>

            <p className="mt-3 mb-0 text-muted font-italic">Product Review Id: {content.id || ""}</p>
            <p className="mt-0 mb-0 text-muted font-italic">Created by
                Id: {content.createdById ? content.createdById || "" : "None"} on {
                    content.createdDate ? new Date(content.createdDate).toLocaleDateString() : "None"}</p>
            <p className="mt-0 mb-0 text-muted font-italic">Modified by
                Id: {content.modifiedById ? content.modifiedById || "" : "None"} on {
                    content.modifiedDate ? new Date(content.modifiedDate).toLocaleDateString() : "None"}</p>
        </div>
    )
}

export default ProductReviewDisplay;