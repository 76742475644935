import React from 'react';
import HelmetComponent from "./utility/HelmetComponent";

const Help: React.FC = () => {

    return (
        <div>
            <HelmetComponent title="Help"/>
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">Help Page</h3>

            <p><strong>Login Problems</strong></p>
            <ul>
                <li>You need to Signup before attempting to login at: <a href="/register">Signup</a></li>
                <li>Login requires your USERNAME and password. Username may be different than your e-mail depending on
                    what you used for Signup. If you have forgotten your username, go to this page to have it emailed to
                    you: <a href="/forgot-username">Forgotten
                        Username</a>
                </li>
                <li>If you have forgotten your password, go to this page: <a href="/reset">Password Reset</a></li>
            </ul>
            <p><strong>Please login and create a support ticket for any of the following here:</strong> <a
                href="/support/add">Support Ticket</a></p>
            <ul>
            <li>Website Error</li>
                <li>Feature Suggestion</li>
                <li>Add or Correct Types, Categories, Countries, or States</li>
                <li>Moderation Request (for content that is inappropriate or does not meet our policies)</li>
            </ul>
            <p><strong>For additional support, email: </strong> <a href="mailto:fragrancefreeresources@gmail.com">fragrancefreeresources@gmail.com</a>.</p>
        </div>

    );
};

export default Help
