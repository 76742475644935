import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import apiRoutes from "../../config/apiRoutes";
import {IState, useTypesContext} from "../../contexts/typeContexts";
import {IBusinessForm, IBusinessSubmit} from "../../interfaces/business.interface";
import BusinessRulesHeader from "./components/BusinessRulesHeader";
import {toast} from 'react-toastify';
import * as Yup from "yup";
import {useFormik} from "formik";
import {replaceNullsWithEmptyString} from "../utility/replaceNullsWithEmptyString";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";

const ValidationSchema = () =>
    Yup.object().shape({
        name: Yup.string().required('Name is required').max(255, 'Maximum of 255 characters allowed'),
        description: Yup.string().required('Description is required').max(1024, 'Maximum of 1023 characters allowed'),
        businessType: Yup.string().required('Type is required'),
        email: Yup.string().email('Invalid email').max(255, 'Maximum of 255 characters allowed'),
        website: Yup.string().required("Business website link required.").max(255, 'Maximum of 255 characters allowed'),
        country: Yup.string().required('Country is required'),
        address1: Yup.string().max(255, 'Maximum of 255 characters allowed'),
        address2: Yup.string().max(255, 'Maximum of 255 characters allowed'),
        city: Yup.string().max(255, 'Maximum of 255 characters allowed'),
        postalCode: Yup.string().max(255, 'Maximum of 255 characters allowed')
    });

const initialValues: IBusinessForm = {
    name: '',
    description: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    phoneNumber: '',
    email: '',
    website: '',
    businessType: '',
    policy: false,
    policyDescription: '',
    latitude: 0.0,
    longitude: 0.0
};

const BusinessEditForm = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {countryList, businessTypes} = useTypesContext();
    const [statesList, setStatesList] = useState<IState[]>([]);
    const pageTitle: string = id === '0' ? "Add Business" : "Edit Business";
    const [isLoading, setIsLoading] = useState(false);
    const handleGoBack = () => { navigate(-1);};



    const formik= useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    function convertFormToSubmit(form: IBusinessForm): IBusinessSubmit {
        return { ...form,
            businessType: {'id': Number(form.businessType)},
            state: form.state === '' ? null : {'id': Number(form.state)},
            country: form.country === '' ? null : {'id': Number(form.country)}
    }}

    const handleSubmit = async (values: IBusinessForm) => {
        // FORM CONVERSION
        const updatedFormValues = convertFormToSubmit(values);

        // LOCATION FETCH

        let url = id !== '0' ? `${apiRoutes.BUSINESSES}/${id}` :`${apiRoutes.BUSINESSES}`;
        let method = id !== '0' ? HttpMethods.PUT : HttpMethods.POST;

        axiosRequest(method, url, "Business", updatedFormValues).then(response => {
            navigate(`/businesses/${response.data.id}`);
        }).catch(error => {
            handleRequestError(error, "Business");
        });
    }
    
    // BUSINESS FETCH SCRIPT
    useEffect(() => {
        const fetchBusiness = async () => {
            if (id !== '0') {
                // eslint-disable-next-line
                const response = axios
                    .get(`${apiRoutes.BUSINESSES}/${id}`)
                    .then(response => {
                        // make sure response is null safe
                        const data = replaceNullsWithEmptyString(response.data);
                        // set values
                        formik.setValues({
                            ...data,
                            'businessType': data.businessType?.id ?? '',
                            'country': data.country?.id ?? '',
                            'state': data.state?.id ?? ''
                        });

                })
            .catch(error => toast.error("Error getting business information"))
                .finally(() => {
                    setIsLoading(false)
                });
            } else {
                setIsLoading(false);
            }
        }
        fetchBusiness();
        // eslint-disable-next-line
    }, [id]);

    // CHANGE SCRIPTS
    const countryChangedHandler = async (countryId: number) => {
        try {
            const response = await axios.get(`${apiRoutes.STATES}/${countryId}`);
            setStatesList(response.data.sort((a: IState, b: IState) => a.name.localeCompare(b.name)));
        } catch (error) {
            setStatesList([]);
            toast.error("Error getting State list");
            console.error(error);
        }
    };

    useEffect(() => {
        if (formik.values.country !== '') {
            countryChangedHandler(Number(formik.values.country));
        }
    }, [formik.values.country]);
    
    //  PAGE RETURN
    if (isLoading) {
        return (
            <div>
                <HelmetEntityComponent title="Business Edit" entity="Business"/>
                <h1 className='mb-3'>{pageTitle}</h1>
                <BusinessRulesHeader/>
                <h2>Page loading... </h2>
            </div>
        )
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Business Edit" entity="Business"/>
                <h1 className='mb-3'>{pageTitle}</h1>
                <BusinessRulesHeader/>

                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Business Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('name')}
                            isInvalid={Boolean(formik.touched.name && formik.errors.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>Business Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('description')}
                            isInvalid={Boolean(formik.touched.description && formik.errors.description)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBusinessType">
                        <Form.Label>Business Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('businessType')}
                            isInvalid={Boolean(formik.touched.businessType && formik.errors.businessType)}
                        >
                            <option value="">Select a Category</option>
                            {businessTypes.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.businessType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formAddress1">
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('address1')}
                            isInvalid={Boolean(formik.touched.address1 && formik.errors.address1)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.address1}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formAddress1">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('address2')}
                            isInvalid={Boolean(formik.touched.address2 && formik.errors.address2)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.address2}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('city')}
                            isInvalid={Boolean(formik.touched.city && formik.errors.city)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.city}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formcountry">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('country')}
                            isInvalid={Boolean(formik.touched.country && formik.errors.country)}
                        >
                            <option value="">Select a Country</option>
                            {countryList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.country}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formState">
                        <Form.Label>State/Province</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('state')}
                            isInvalid={Boolean(formik.touched.state && formik.errors.state)}
                        >
                            <option value="">Select a State/Province</option>
                            {statesList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.state}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPostalCode">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('postalCode')}
                            isInvalid={Boolean(formik.touched.postalCode && formik.errors.postalCode)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.postalCode}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('phoneNumber')}
                            isInvalid={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.phoneNumber}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('email')}
                            isInvalid={Boolean(formik.touched.email && formik.errors.email)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formWebsite">
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('website')}
                            isInvalid={Boolean(formik.touched.website && formik.errors.website)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.website}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPolicy">
                        <Form.Label>Fragrance Free Policy</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={formik.values.policy}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={Boolean(formik.touched.policy && formik.errors.policy)}
                            name='policy'
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.policy}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPolicyDescription">
                        <Form.Label>Fragrance Free Policy Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('policyDescription')}
                            isInvalid={Boolean(formik.touched.policyDescription && formik.errors.policyDescription)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.policyDescription}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br/>
                    <Button type="submit">Submit</Button>
                    <Button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</Button>
                </Form>
            </div>
        );
    }
};

export default BusinessEditForm;
