import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {Col, Container, Nav, Navbar, Row} from 'react-bootstrap';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Login from "./entities/auth/Login";
import Logout from "./entities/auth/Logout";
import Register from "./entities/auth/Register";
import ForgotUsername from "./entities/auth/ForgotUsername";
import ResetPassword from "./entities/auth/ResetPassword";
import Home from "./entities/HomePageSite";
import Profile from "./entities/users/Profile";
import UserEdit from "./entities/users/UserEdit";
import User from "./entities/users/User"
import Help from "./entities/Help";
import Terms from "./entities/Terms";

import BusinessesListPage from "./entities/businesses/BusinessesListPage";
import BusinessViewPage from "./entities/businesses/BusinessViewPage";
import BusinessReviewPage from "./entities/reviews/components/BusinessReview";
import BusinessEditForm from "./entities/businesses/BusinessEditForm";

import HomesListPage from "./entities/homes/HomesListPage";
import HomePage from "./entities/homes/HomePage";
import HomeReviewPage from "./entities/reviews/components/HomeReview";
import HomeEditForm from "./entities/homes/HomeEditForm";

import ProductsListPage from "./entities/products/ProductsListPage";
import ProductPage from "./entities/products/ProductPage";
import ProductReviewPage from "./entities/reviews/components/ProductReview";
import ProductEditFormV3 from "./entities/products/ProductEditFormV3";

import Amazon from "./entities/amazon/Amazon";

import SupportList from "./entities/support/SupportList";
import SupportAdd from "./entities/support/SupportAdd";
import SupportPage from "./entities/support/SupportPage";
import SupportEdit from "./entities/support/SupportEdit";
import UsersList from "./entities/users/UserList";

import SocialEditForm from "./entities/socials/SocialEditForm";

import About from "./entities/About";
import Privacy from "./entities/PrivacyPolicy";
import {TypesProvider} from "./contexts/typeContexts";
import SocialsListPage from "./entities/socials/SocialsListPage";
import SocialViewPage from "./entities/socials/SocialViewPage";
import {AuthProvider, useAuth} from "contexts/authContext";

// Citation: Adapted from: https://www.bezkoder.com/react-typescript-authentication-example/
// Init Repo: https://github.com/bezkoder/react-typescript-authentication-example/tree/master

const App: React.FC = () => {
    // wrap context providers
    return (
        <AuthProvider>
            <TypesProvider>
                    <AppContent />
            </TypesProvider>
        </AuthProvider>
    )
}

const AppContent: React.FC = () => {
    const { currentUser, setCurrentUser, showModeratorPage, setShowModeratorPage, showAdminPage, setShowAdminPage } = useAuth();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState(location.pathname);

    useEffect(() => {
        const pathname = location.pathname;
        const pathParts = pathname.split('/');
        setActiveKey(pathParts[1]);
    }, [location]);

    useEffect(() => {
        const userStr = localStorage.getItem("user");
        let user = undefined;
        if (userStr) user = JSON.parse(userStr);

        if (user) {
            setCurrentUser(user);
            setShowModeratorPage(user.roles.includes("ROLE_MODERATOR"));
            setShowAdminPage(user.roles.includes("ROLE_ADMIN"));
        }
    }, [setCurrentUser, setShowModeratorPage, setShowAdminPage]);

    return (
        <div className="d-flex flex-column min-vh-100">
            <ToastContainer autoClose={3000}/>
            <header>
                <Navbar className="custom-navbar" bg="light">
                    <Container>
                        <Navbar.Brand href="/" className="custom-navbar-brand">
                            <img
                                alt=""
                                src="/apple-touch-icon.png"
                                width="55"
                                height="55"
                                className="d-inline-block align-middle"
                            />{' '}
                            FragranceFreeResources.com
                        </Navbar.Brand>
                    </Container>
                </Navbar>
                <Navbar collapseOnSelect expand="sm" className="bg-body-tertiary custom-navbar">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav variant="underline" activeKey={activeKey} className="me-auto">
                                <Nav.Item>
                                    <Nav.Link href="/" eventKey="">Home</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/businesses" eventKey="businesses">Businesses</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/products" eventKey="products">Products</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/homes" eventKey="homes">Rental Homes</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/socials" eventKey="socials">Social Groups</Nav.Link>
                                </Nav.Item>
                                {showModeratorPage && (
                                    <Nav.Link href="/support" eventKey="support">Support</Nav.Link>
                                )}
                                {showAdminPage && (
                                    <Nav.Link href="/users" eventKey="users">Users</Nav.Link>
                                )}
                            </Nav>

                            {/* Right-aligned NavDropdown */}
                            <Nav className="ms-auto custom-navbar-nav">
                                {currentUser ? (
                                    <>
                                        <Nav.Item>
                                            <Nav.Link href="/profile" eventKey="/profile">Profile</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link href="/logout" eventKey="/logout">Logout</Nav.Link>
                                        </Nav.Item>
                                    </>
                                ) : (
                                    <Nav.Item>
                                        <Nav.Link href="/login" eventKey="/login">Login</Nav.Link>
                                    </Nav.Item>
                                )}

                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            <Container className="flex-grow-1 my-4">
                <Row>
                    <Col>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/home" element={<Home/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/logout" element={<Logout/>}/>
                            <Route path="/register" element={<Register/>}/>
                            <Route path="/reset" element={<ResetPassword/>}/>
                            <Route path="/forgot-username" element={<ForgotUsername/>}/>
                            <Route path="/profile" element={<Profile/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/help" element={<Help/>}/>
                            <Route path="/privacy" element={<Privacy/>}/>
                            <Route path="/terms" element={<Terms/>}/>

                            <Route path="/user/edit" element={<UserEdit/>}/>
                            <Route path="/users" element={<UsersList/>}/>

                            <Route path="/support" element={<SupportList/>}/>
                            <Route path="/support/add" element={<SupportAdd/>}/>
                            <Route path="/about" element={<About/>}/>

                            <Route path="/amazon" element={<Amazon/>}/>

                            <Route path="/businesses" element={<BusinessesListPage/>}/>
                            <Route path="businesses">
                                <Route path=":id" element={<BusinessViewPage/>}/>
                                <Route path=":id/edit" element={<BusinessEditForm/>}/>
                                <Route path=":businessId/reviews/:id" element={<BusinessReviewPage/>}/>
                            </Route>

                            <Route path="homes" element={<HomesListPage/>}/>
                            <Route path="homes">
                                <Route path=":id" element={<HomePage/>}/>
                                <Route path=":id/edit" element={<HomeEditForm/>}/>
                                <Route path=":homeId/reviews/:id" element={<HomeReviewPage/>}/>
                            </Route>

                            <Route path="products" element={<ProductsListPage/>}/>
                            <Route path="products">
                                <Route path=":id" element={<ProductPage/>}/>
                                <Route path=":id/editV3" element={<ProductEditFormV3/>}/>
                                <Route path=":productId/reviews/:id" element={<ProductReviewPage/>}/>
                            </Route>

                            <Route path="/socials" element={<SocialsListPage/>}/>
                            <Route path="socials">
                                <Route path=":id" element={<SocialViewPage/>}/>
                                <Route path=":id/edit" element={<SocialEditForm/>}/>
                            </Route>

                            <Route path="/support" element={<SupportList/>}/>
                            <Route path="support">
                                <Route path=":id" element={<SupportPage/>}/>
                                <Route path=":id/edit" element={<SupportEdit/>}/>
                            </Route>

                            <Route path="/users" element={<UsersList/>}/>
                            <Route path="users">
                                <Route path=":id" element={<User/>}/>
                            </Route>

                        </Routes>
                    </Col>
                </Row>
                <div className="pb-5"></div>
            </Container>
            <footer className="pt-2 bg-light-subtle text-center">
                <Container>
                    <Row>
                        <Col>
                            <Col className="text-center">
                                <a href="/about" className="footer-link">About</a>
                                <span className="mx-3">|</span>
                                <a href="/help" className="footer-link">Help</a>
                                <span className="mx-3">|</span>
                                <a href="/privacy" className="footer-link">Privacy</a>
                                <span className="mx-3">|</span>
                                <a href="/terms" className="footer-link">Terms and Conditions</a>
                                {/* Add more links as needed */}
                            </Col>


                            <Row className="justify-content-center">&copy; Joel Strong 2024</Row>
                        </Col>
                </Row>
            </Container>
        </footer>
        </div>

)
    ;
};

export default App;