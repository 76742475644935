import React from "react";
import {IUserRegisterForm, IUserRegisterSubmit,} from "../../interfaces/user.interface";
import {NavigateFunction, useNavigate} from 'react-router-dom';
import apiRoutes from "../../config/apiRoutes";
import {Button, Form} from "react-bootstrap";
import {toast} from 'react-toastify';
import * as Yup from "yup";
import {useFormik} from "formik";
import HelmetComponent from "../utility/HelmetComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";

const Register: React.FC = () => {
    const navigate: NavigateFunction = useNavigate();


    const ValidationSchema = () =>
        Yup.object().shape({
            username: Yup.string().required("Username is required")
                .min(6, "Username must be at least 6 characters")
                .max(20, "Username must be at most 20 characters"),
            password: Yup.string().required("Password is required")
                .min(6, "Password must be at least 6 characters"),
            email: Yup.string().required("Email is required").email("Invalid email address"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password')], 'Passwords must match')
                .required('Confirm Password is required'),
        });

    const initialValues: IUserRegisterForm = {
        username: '',
        password: '',
        confirmPassword: '',
        email: ''
    };

    const formik= useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    const handleSubmit = async (values: IUserRegisterForm) => {
            // FORM CONVERSION
            const updatedFormValues: IUserRegisterSubmit = {
                username: values.username,
                password: values.password,
                email: values.email
            }

        axiosRequest(HttpMethods.POST, `${apiRoutes.AUTH}/signup`, "Signup", updatedFormValues).then(response => {
            navigate(`/login`);
            toast.success("Registration successful. Please login.")
        }).catch(error => {
            handleRequestError(error, "Signup");
        });
    }

    return (
        <div className="col-md-12">
            <HelmetComponent
                title="Register"
            />
            <div className="card card-container">
                <p style={{textAlign: 'center', width: '100%', fontSize: '2rem'}}>User Signup</p>
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />
                <Form noValidate onSubmit={formik.handleSubmit}>

                    <Form.Group controlId="formUsername">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('username')}
                            isInvalid={Boolean(formik.touched.username && formik.errors.username)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.username}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('email')}
                            isInvalid={Boolean(formik.touched.email && formik.errors.email)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            {...formik.getFieldProps('password')}
                            isInvalid={Boolean(formik.touched.password && formik.errors.password)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formNewPasswordVerify">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            {...formik.getFieldProps('confirmPassword')}
                            isInvalid={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br/>
                    <div className="text-center">
                        <Button type="submit">Register</Button>
                    </div>
                </Form>

            </div>
        </div>
    );
};

export default Register;