import React, {ChangeEvent, useEffect} from "react";
import AmazonRulesHeader from "./AmazonRulesHeader";
import {Form} from "react-bootstrap";


const Amazon: React.FC = () => {
    const [url, setUrl] = React.useState("");
    const [link, setLink] = React.useState("");
    const affiliateId = "fragrancefr0d-20";

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setUrl(event.target.value);
    }

    useEffect(() => {
        const productIdMatch = url.match(/\/dp\/([A-Z0-9]+)/);

        if (!productIdMatch) {
            setLink("Invalid Amazon Product URL")
        } else {
            const productId = productIdMatch[1];
            setLink(`https://www.amazon.com/dp/${productId}?tag=${affiliateId}`);
        }


    }, [url]);

    return (
        <>

            <h2>Purchase Affiliated Amazon Product</h2>
            {/*            <Form>
                <Form.Group controlId="formBasicText">
                    <Form.Label>Input Amazon Product URL:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter text"
                        value={url}
                        onChange={handleInputChange}
                    />
                </Form.Group>
            </Form>

            <br/>
            <p>Your custom Affiliate purchase link:</p>
            <p><a href={link}>{link}</a></p>*/}

            <p><a href="https://www.amazon.com/dp/B08XFDKF5W?tag=fragrancefr0d-20">Gonzaga University Stickers
                Bulldogs GU Zags Vinyl Decals Laptop Water Bottle Car Scrapbook 11.42x8.9 Sheet T2 (Type 2)</a> </p>
            <img
                src="https://m.media-amazon.com/images/I/91LbiZpuxZS._AC_SL1500_.jpg"
                alt="Description of the image"
                width="500px"
            />

        </>
    )
}

export default Amazon