import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import apiRoutes from "../../config/apiRoutes";
import {IState, useTypesContext} from "../../contexts/typeContexts";
import {IHomeForm, IHomeSubmit} from "../../interfaces/homes.interface";
import HomeRulesHeader from "./components/HomeRulesHeader";
import {toast} from 'react-toastify';
import * as Yup from "yup";
import {useFormik} from "formik";
import {replaceNullsWithEmptyString} from "../utility/replaceNullsWithEmptyString";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {HttpMethods} from "../../routes/HttpMethods";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {Entities} from "../../routes/Entities";

const HomesEditForm = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {countryList, homeTypes} = useTypesContext();
    const [statesList, setStatesList] = useState<IState[]>([]);
    const pageTitle: string = id === '0' ? "Add Homes" : "Edit Homes";
    const [isLoading, setIsLoading] = useState(false);
    const handleGoBack = () => { navigate(-1);};
    const entity = Entities.HOME;

    const ValidationSchema = () =>
        Yup.object().shape({
            name: Yup.string().required('Name is required').max(255, 'Maximum of 255 characters allowed'),
            description: Yup.string().required('Description is required').max(1024, 'Maximum of 1024 characters allowed'),
            homeType: Yup.string().required('Type is required'),
            email: Yup.string().email('Invalid email'),
            country: Yup.string().required('Country is required'),
            city: Yup.string().required('City is required').max(255, 'Maximum of 255 characters allowed'),
            state: Yup.string().required('State is required'),
            address1: Yup.string().max(255, 'Maximum of 255 characters allowed'),
            address2: Yup.string().max(255, 'Maximum of 255 characters allowed'),
            postalCode: Yup.string().max(255, 'Maximum of 255 characters allowed')
        });

    const initialValues: IHomeForm = {
        name: '',
        description: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        phoneNumber: '',
        email: '',
        website: '',
        homeType: '',
        people: 0,
        petsAllowed: false,
        policy: false,
        policyDescription: '',
        bedrooms: 0,
        bathrooms: 0,
        latitude: 0.0,
        longitude: 0.0
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    function convertFormToSubmit(form: IHomeForm): IHomeSubmit {
        return {
            ...form,
            homeType: {'id': Number(form.homeType)},
            state: form.state === '' ? null : {'id': Number(form.state)},
            country: form.country === '' ? null : {'id': Number(form.country)}
        }
    }

    const handleSubmit = async (values: IHomeForm) => {
        // FORM CONVERSION
        const updatedFormValues = convertFormToSubmit(values);

        let url = id !== '0' ? `${apiRoutes.HOMES}/${id}` :`${apiRoutes.HOMES}`;
        let method = id !== '0' ? HttpMethods.PUT : HttpMethods.POST;

        axiosRequest(method, url, entity, updatedFormValues).then(response => {
            navigate(`/homes/${response.data.id}`);
        }).catch(error => {
            handleRequestError(error, entity);
        });
    }

    const fetchHomes = async () => {
        if (id !== '0') {
            axiosRequest(HttpMethods.GET, `${apiRoutes.HOMES}/${id}`, entity).then(response => {
                // make sure response is null safe
                const data = replaceNullsWithEmptyString(response.data);
                // set values
                formik.setValues({
                    ...data,
                    'homeType': data.homeType?.id ?? '',
                    'country': data.country?.id ?? '',
                    'state': data.state?.id ?? ''
                });
            }).catch(error => {
                handleRequestError(error, entity);
            }).finally( () => {
                setIsLoading(false);
            })
        } else {
            setIsLoading(false);
        }
    }

    // RENTAL HOME FETCH SCRIPT
    useEffect(() => {
        fetchHomes();
        // eslint-disable-next-line
    }, [id]);

    // CHANGE SCRIPTS
    const countryChangedHandler = async (countryId: number) => {
        try {
            const response = await axios.get(`${apiRoutes.STATES}/${countryId}`);
            setStatesList(response.data.sort((a: IState, b: IState) => a.name.localeCompare(b.name)));
        } catch (error) {
            setStatesList([]);
            toast.error("Error getting State list");
        }
    };

    useEffect(() => {
        if (formik.values.country !== '') {
            countryChangedHandler(Number(formik.values.country));
        }
    }, [formik.values.country]);

    //  PAGE RETURN

    if (isLoading) {
        return (
            <div>
                <HelmetEntityComponent title="Rental Home Edit" entity="Rental Home"/>
                <h1 className='mb-3'>{pageTitle}</h1>
                <HomeRulesHeader/>
                <h2>Page loading... </h2>
            </div>
        )
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Rental Home Edit" entity="Rental Home"/>
                <h1 className='mb-3'>{pageTitle}</h1>
                <HomeRulesHeader/>

                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Home Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('name')}
                            isInvalid={Boolean(formik.touched.name && formik.errors.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>Home Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('description')}
                            isInvalid={Boolean(formik.touched.description && formik.errors.description)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formHomesType">
                        <Form.Label>Rental Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('homeType')}
                            isInvalid={Boolean(formik.touched.homeType && formik.errors.homeType)}
                        >
                            <option value="">Select a Category</option>
                            {homeTypes.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.homeType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formAddress1">
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('address1')}
                            isInvalid={Boolean(formik.touched.address1 && formik.errors.address1)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.address1}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formAddress1">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('address2')}
                            isInvalid={Boolean(formik.touched.address2 && formik.errors.address2)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.address2}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('city')}
                            isInvalid={Boolean(formik.touched.city && formik.errors.city)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.city}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCountryId">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('country')}
                            isInvalid={Boolean(formik.touched.country && formik.errors.country)}
                        >
                            <option value="">Select a Country</option>
                            {countryList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.country}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formStateId">
                        <Form.Label>State/Province</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('state')}
                            isInvalid={Boolean(formik.touched.state && formik.errors.state)}
                        >
                            <option value="">Select a State/Province</option>
                            {statesList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.state}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPostalCode">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('postalCode')}
                            isInvalid={Boolean(formik.touched.postalCode && formik.errors.postalCode)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.postalCode}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('phoneNumber')}
                            isInvalid={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.phoneNumber}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('email')}
                            isInvalid={Boolean(formik.touched.email && formik.errors.email)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formWebsite">
                        <Form.Label>Rental Home Website</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('website')}
                            isInvalid={Boolean(formik.touched.website && formik.errors.website)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.website}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPeople">
                        <Form.Label>Maximum People</Form.Label>
                        <Form.Control
                            type="number"
                            {...formik.getFieldProps('people')}
                            isInvalid={Boolean(formik.touched.people && formik.errors.people)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.people}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBedrooms">
                        <Form.Label>Bedrooms</Form.Label>
                        <Form.Control
                            type="number"
                            {...formik.getFieldProps('bedrooms')}
                            isInvalid={Boolean(formik.touched.bedrooms && formik.errors.bedrooms)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.bedrooms}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBathrooms">
                        <Form.Label>Bathrooms</Form.Label>
                        <Form.Control
                            type="number"
                            {...formik.getFieldProps('bathrooms')}
                            isInvalid={Boolean(formik.touched.bathrooms && formik.errors.bathrooms)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.bathrooms}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPolicy">
                        <Form.Label>Fragrance Free Policy</Form.Label>
                        <Form.Check
                            type="switch"
                            id="policy-switch"
                            checked={formik.values.policy}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={Boolean(formik.touched.policy && formik.errors.policy)}
                            name='policy'
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.policy}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPolicyDescription">
                        <Form.Label>Fragrance Free Policy Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('policyDescription')}
                            isInvalid={Boolean(formik.touched.policyDescription && formik.errors.policyDescription)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.policyDescription}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPetsAllowed">
                        <Form.Label>Pets Allowed</Form.Label>
                        <Form.Check
                            type="switch"
                            id="pet-switch"
                            checked={formik.values.petsAllowed}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={Boolean(formik.touched.petsAllowed && formik.errors.petsAllowed)}
                            name='petsAllowed'
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.petsAllowed}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br/>
                    <Button type="submit">Submit</Button>
                    <Button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</Button>
                </Form>
            </div>
        );
    }
};

export default HomesEditForm;
