import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import {IPaginationComponent} from "../../interfaces/pageable.interface";

const PaginationComponent: React.FC<IPaginationComponent> = ({ data, handlePageChange }) => {
    if (!data) {
        return (
            <>
                <br />
            </>
        );
    } else {

        const items = [];
        for (let number = 0; number < data.totalPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === data.pageable.pageNumber}
                                 onClick={() => handlePageChange(number)}>
                    {number + 1}
                </Pagination.Item>
            );
        }

        const nextPage = data.pageable.pageNumber + 1 < data.totalPages ? data.pageable.pageNumber + 1 : data.pageable.pageNumber;
        const prevPage = data.pageable.pageNumber - 1 >= 0 ? data.pageable.pageNumber - 1 : 0;

        return (
            <Pagination>
                <Pagination.Prev onClick={() => handlePageChange(prevPage)} />
                {items}
                <Pagination.Next onClick={() => handlePageChange(nextPage)} />
            </Pagination>
        );
    }
};

export default PaginationComponent;