import React from 'react';
import {IBusiness} from '../../../interfaces/business.interface';
import Address from "../../utility/Address"
import BusinessReviewList from "../../reviews/components/BusinessReviewList";

interface BusinessProps {
    business: IBusiness;
}

const Business: React.FC<BusinessProps> = ({business}) => {

    return (
        <div>
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">{business.name}</h3>
            <h4>Business Information</h4>
            <div className="ml-3">
                <p className='mb-2'><strong>Business Name: </strong>{business.name || ""}</p>
                <p className='mb-2'><strong>Business
                    Type:</strong> {business.businessType ? business.businessType.name || "" : ""}</p>
                <p className='mb-2'><strong>Business Description:</strong> {business.description || ""}</p>
                <p className='mb-2'><strong>Fragrance Free Policy:</strong> {business.policy ? "Yes" : "No"}</p>
                <p className='mb-2'><strong>Fragrance Free Policy Description:</strong> {business.policyDescription || ""}</p>
                <p className="mt-3 mb-0 font-weight-bold text-success font-italic">Please click the link below to view
                    Business information and photographs</p>
                <p className='mb-2'><strong>Website: </strong>
                    <a href={business.website || "#"}
                       onClick={e => {
                           e.preventDefault();
                           if (business.website) window.open(business.website, "_blank")
                       }}>
                        {business.website || ""}
                    </a></p>
                <Address
                    address1={business.address1}
                    address2={business.address2}
                    city={business.city}
                    state={business.state}
                    country={business.country}
                    postalCode={business.postalCode}
                    phoneNumber={business.phoneNumber}
                    email={business.email}
                />
                <br />
                <h4 className='mb-2'>Business Reviews:</h4>
                <p className='mb-2'><strong>Number of Reviews: </strong>{business.reviews} </p>
                <p className='mb-2'><strong>Rating (out of 5): </strong>{business.rating.toFixed(2)} </p>

                <BusinessReviewList businessId={Number(business.id)}/>


                <p className="mt-3 mb-0 text-muted font-italic">Business Id: {business.id || ""}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Created by
                    Id: {business.createdById ? business.createdById || "" : "None"} on {
                        business.createdDate ? new Date(business.createdDate).toLocaleDateString() : "None"}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Modified by
                    Id: {business.modifiedById ? business.modifiedById || "" : "None"} on {
                        business.modifiedDate ? new Date(business.modifiedDate).toLocaleDateString() : "None"}</p>
            </div>

        </div>
    );

};

export default Business;