import React from 'react';
import HelmetComponent from "./utility/HelmetComponent";

const About: React.FC = () => {

    return (
        <div>
            <HelmetComponent title="About" />
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">About this Website</h3>
            <div>
                <p><strong>Usage</strong></p>
                <p>This website is intended to be a resource for sharing and reviewing fragrance free businesses,
                    products,
                    rental homes, and social groups. This website hopes to serve all people looking for fragrance safe
                    environments
                    and is not limited to a specific health concern. Each person is different and what is safe for one
                    person may not
                    be safe for another. Please submit reviews of your experiences (both positive and negative) for the
                    community
                    to have perspective. Entries listed here are not guarantees of the safety or effectiveness of any
                    business, product,
                    rental home, or social group, and due diligence is necessary on the part of the user. For more
                    information on the rules, terms, and conditions of this site, visit our <a
                        href="/terms">Terms and Conditions.</a></p>


                <p><strong>Dedication</strong></p>
                <p>One of my family members is affected by a severe sensitivity to fragrances, and I have watched and
                    listened to many of their struggles with isolation trying to avoid any scented environments. I am
                    aware of a large international community of people who experience the same struggles and I wanted to
                    create a resource for the community to share their individual knowledge of safe businesses,
                    products, rental homes, and social groups with the larger community. My hope is that this web
                    application will be a
                    helpful source of information and improve the quality of life for people that have a sensitivity to
                    fragrances.</p>

                <p><strong>Web Application Features</strong></p>
                <ul>
                    <li>Security - Implemented Spring Security using JWT with backend access privileges for each
                        endpoint and frontend dynamic page rendering based on roles and record creator.
                    </li>
                    <li>User Interface - Developed a dynamic SPA using React, Typescript, and React-Bootstrap. Used
                        React-Toastify to display non-obtrusive messages to the user and Modals for simple form
                        interactions.
                    </li>
                    <li>Cloud Development - Used Amazon Web Services to deploy MVC elements: AWS Lightsail RDS for MySQL
                        hosting, AWS Amplify for hosting the frontend, and AWS Lightsail Container running a Dockerized
                        SpringBoot/Java application backend.
                    </li>
                    <li>Database Design - Database schema was created/updated using Spring Boot JPA based on annotated
                        class variable specifications. Used MySQLWorkbench to populate initial values and make
                        modifications
                        during testing.
                    </li>
                    <li>Compatibility - Used flexible React and Bootstrap features to build a dynamic website
                        accessible on cell phones, tablets, and computers of different screen sizes.
                    </li>
                    <li>Pagination - Used Spring Boot Pageable and React-Boostrap Pagination to deliver paginated result
                        lists to the user.
                    </li>
                    <li>Performance - Implemented memoized context caching on the frontend to improve application
                        performance. Broke the application into efficient API calls to decrease the data needed for page
                        rendering.
                    </li>
                    <li>Entities - Enabled CRUD operations on a range of entities: Businesses, Products, Rental Homes,
                        Support Groups,
                        Entity Reviews, Support Tickets, and Users.
                    </li>
                    <li>SEO - Used React-Helmet to create dynamic page meta tags, sitemap for sitemap.xml generation,
                        and Google Search Console for optimization.
                    </li>
                    <li>Lighthouse - Used Google Lighthouse analysis to improve the web application design including
                        Progressive Web Application badge.
                    </li>
                </ul>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src="/lighthouse.png" alt="google lighthouse analysis findings" width="600"/>
                </div>

                <p><strong>Continuous Development</strong></p>
                <p>Since the release of the web application, features that add significant value have been added:</p>
                <ul>
                    <li>Forgotten username and Password Reset requests - frontend pages and backend routes added.</li>
                    <li>Simple email support - the server will automatically send e-mails for support tickets, forgotten usernames and password resets.</li>
                    <li>DevOps - created a shell script and Dockerfile to automatically package, Dockerize, and push the container to AWS for deployment.</li>
                    <li>HttpOnly Secure Cookies - major refactor from using localStorage JWTs to HttpOnly Secure cookies.</li>
                    <li>JWT Refresh Tokens - on auth failure, automatically request a refresh token and replace existing cookie if refresh token is not expired. </li>
                    <li>Help and Terms & Condition pages - added Help page to consolidate user advise and options and Terms for legal compliance.</li>
                </ul>

                <p><strong>Development</strong></p>
                <p>This full stack web application was primarily developed over 11 weeks as part of a Personal Projects
                    elective class as part of my Computer Science bachelors degree at Oregon State University. A further
                    6 weeks was spent to test and refine before release and continues to be supported. In
                    addition to my classwork, I dedicated myself to learning additional languages and frameworks with
                    the goal of independently developing a web application. This website is a realization of those
                    efforts.</p>
                <ul>
                    <li>Frontend developed using React, Typescript, React-Bootstrap using JetBrains WebStorm IDE. Hosted
                        on AWS Amplify.
                    </li>
                    <li>Backend developed using Spring Boot (Web, Security, JPA) and Java in JetBrains IntelliJ for the
                        Backend. Hosted on AWS Lightsail Container running as a Dockerized container.
                    </li>
                    <li>Database developed using MySQLWorkbench and JPA ODM. Hosted on AWS Lightsail Database.</li>
                    <li>GitHub for VCS</li>
                </ul>

                <p><strong>Personal Website</strong></p>
                <p>To see more of my work, please visit my website at <a
                    href="http://jdstrongpdx.com">jdstrongpdx.com</a>.</p>
            </div>
        </div>

    );
};

export default About
