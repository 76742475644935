import React from 'react';
import {Button, Form} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import apiRoutes from "../../config/apiRoutes";
import {useTypesContext} from "../../contexts/typeContexts";
import {ISupportForm, ISupportSubmit} from "../../interfaces/support.interface";
import * as Yup from "yup";
import {useFormik} from "formik";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";

const SupportAdd = () => {
    const navigate = useNavigate();
    const {entityTypes, supportTypes} = useTypesContext();
    const handleGoBack = () => {navigate(-1);};

    const ValidationSchema = () =>
        Yup.object().shape({
            name: Yup.string().required('Name is required').max(255, 'Maximum of 255 characters allowed'),
            description: Yup.string().max(1024, 'Maximum of 1024 characters allowed'),
            supportType: Yup.string().required('Support Type is required'),
        });

    const initialValues: ISupportForm = {
        name: '',
        description: '',
        entityId: '',
        entityType: '',
        supportType: '',
        email: false
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    function convertFormToSubmit(form: ISupportForm): ISupportSubmit {
        return {
            ...form,
            entityType: form.entityType === '' ? null : {'id': Number(form.entityType)},
            supportType: form.supportType === '' ? null : {'id': Number(form.supportType)},
        }
    }

    const handleSubmit = async (values: ISupportForm) => {
        const updatedFormValues = convertFormToSubmit(values);
        axiosRequest(HttpMethods.POST, `${apiRoutes.SUPPORT}`, "Support Ticket", updatedFormValues).then(response => {
            navigate(`/`);
        }).catch(error => {
            handleRequestError(error, "Support Ticket");
        });
    }

    return (
        <div>
            <HelmetEntityComponent title="Support Ticket Add" entity="Support Ticket"/>
                <h1 className='mb-3'>Add a Support Ticket</h1>

                <Form noValidate onSubmit={formik.handleSubmit}>

                    <Form.Group controlId="formName">
                        <Form.Label>Ticket Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('name')}
                            isInvalid={Boolean(formik.touched.name && formik.errors.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>Ticket Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('description')}
                            isInvalid={Boolean(formik.touched.description && formik.errors.description)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEntityType">
                        <Form.Label>Entity Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('entityType')}
                            isInvalid={Boolean(formik.touched.entityType && formik.errors.entityType)}
                        >
                            <option value="">Select an Entity Type</option>
                            {entityTypes.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.entityType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formSupportType">
                        <Form.Label>Support Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('supportType')}
                            isInvalid={Boolean(formik.touched.supportType && formik.errors.supportType)}
                        >
                            <option value="">Select a Support Type</option>
                            {supportTypes.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.supportType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <p><strong>Each business, product, rental home, and review has a unique id such as the image below.
                        <br />If applicable for this ticket, enter this id in the Entity Id field below:</strong></p>
                    <img src="/support.png" alt="support Id example" width="300"/>

                    <Form.Group controlId="formEntityId">
                        <Form.Label>Entity Id</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('entityId')}
                            isInvalid={Boolean(formik.touched.entityId && formik.errors.entityId)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.entityId}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Send Me Email Updates on this Ticket</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={Boolean(formik.touched.email && formik.errors.email)}
                            name='email'
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br/>
                    <Button type="submit">Submit</Button>
                    <Button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</Button>
                    <br />
                    <p>Please fill out the form above for any errors, moderation requests, or suggestions. For any other
                        concerns, email us at: <a
                            href="mailto:fragrancefreeresources@gmail.com">fragrancefreeresources@gmail.com</a></p>
                </Form>
        </div>
);
}

export default SupportAdd;
