import {Table} from "react-bootstrap";
import React from "react";
import {IUser} from "../../../interfaces/user.interface";

interface IProps {
    content: IUser[];
}

const UsersTable: React.FC<IProps> = ({ content }) => {
    if (content.length === 0) {
        return (
            <>
                <br />
                <h4>No Users Found...</h4>
                <br />
            </>
        )
    } else {
        return (
            <>
                <Table size="md" striped bordered hover responsive >
                    <thead className="thead-light">
                    <tr>
                        <th>Id</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>State</th>
                        <th>Country</th>

                    </tr>
                    </thead>
                    <tbody>
                    {content.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() => window.location.href = `/users/${item.id}`}
                            style={{cursor: "pointer"}}
                        >
                            <td>{item.id}</td>
                            <td>{item.username || ""}</td>
                            <td>{item.email || ""}</td>
                            <td>{item.name || ""}</td>
                            <td>{item.state ? item.state.name || "" : ""}</td>
                            <td>{item.country ? item.country.name || "" : ""}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        )
    }
}

export default UsersTable;