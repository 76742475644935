import React from 'react';


const SocialRulesHeader: React.FC = () => {
    return (
        <div className='mb-4'>
            <h4>Social Group Rules</h4>
            <p>By adding a social group, you agree to the following rules:</p>
            <ul>
                <li>You cannot add any social media or links to which you or someone you know would make a profit.</li>
                <li>Information submitted must be accurate and appropriate in nature. Submitting false or inappropriate
                    content will result in your account being locked.
                </li>
                <li>Social groups must offer fragrance-free environments or services that aid in fragrance-free living.</li>
                <li>Any user may edit a social group details for accuracy or completeness.</li>
                <li>Only users that created the social group or a moderator can delete a social. Please submit a support ticket to delete a social group if needed.</li>
            </ul>
        </div>
    );
};

export default SocialRulesHeader;