import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import Social from './components/Social';
import apiRoutes from "../../config/apiRoutes";
import authUser from "../../services/auth.user";
import {ISocial} from "../../interfaces/social.interface";
import {toast} from "react-toastify";
import Verify from "../utility/Verify";
import {Button} from "react-bootstrap";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";

const SocialViewPage: React.FC = () => {
    const {id} = useParams();
    const [social, setSocial] = useState<ISocial>();
    const authLevel = authUser(social?.createdById || 0)
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSocial = async () => {
            try {
                const response = await axios.get(apiRoutes.SOCIALS + `/${id}`);
                if (!response.data) {
                    toast.error("Failed to get Social Groups.")
                    return
                }
                setSocial(response.data);
            } catch (error) {
                console.error('Error fetching social data:', error);
            }
        };
        fetchSocial();
    }, [id]);

    const handleEditSocial = () => {
        window.location.href = apiRoutes.BASE + `/socials/${id}/edit`;
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleDelete = async () => {
        axiosRequest(HttpMethods.DELETE, apiRoutes.SOCIALS + `/${id}`, "Social Group").then(response => {
            navigate('/socials');
        }).catch(error => {
            handleRequestError(error, "Social Group");
        });
    };

    if (!social) {
        return (
            <div>
                <HelmetEntityComponent title="Social Group View" entity="Social Group"/>
                Loading social...
            </div>);
    } else if (authLevel === 1) {
        return (
            <div>
                <HelmetEntityComponent title="Social Group View" entity="Social Group"/>
                <Social social={social}/>
                <button className="btn btn-primary m-2" onClick={handleEditSocial}>Edit Social Group</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        );
    } else if (authLevel >= 2) {
        return (
            <div>
                <HelmetEntityComponent title="Social Group View" entity="Social Group"/>
                <Social social={social}/>
                <div style={{display: 'flex', alignItems: 'left', justifyContent: 'flex-start'}}>
                    <button className="btn btn-primary m-2" onClick={handleEditSocial}>Edit Social Group</button>
                    <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
                    <Verify onConfirm={handleDelete} confirmText={"Delete Group"}
                            messageText={"Are you sure you want to proceed? This action cannot be undone."}>
                        <Button className="btn btn-danger m-2">Delete this Group</Button>
                    </Verify>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Social Group View" entity="Social Group"/>
                <Social social={social}/>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
                <p>Please login to add or edit Social Groups and Reviews.</p>
            </div>
        );
    }
};

export default SocialViewPage;
