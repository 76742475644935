import React from 'react';
import {IAddress} from "../../interfaces/address.interface";

const Address = (address: IAddress) => {

    return (
        <div>
            {address.address1
                ? (<p className='mb-2'>
                    <strong>Address:</strong> {address.address1} {address.address2 || ""}
                </p>)
                : null
            }
            {address.city
                ? (<p className='mb-2'>
                    <strong>City:</strong> {address.city}
                </p>)
                : null
            }
            {address.state
                ? (<p className='mb-2'>
                    <strong>State/Province:</strong> {address.state.name}
                </p>)
                : null
            }
            {address.country
                ? (<p className='mb-2'>
                    <strong>Country:</strong> {address.country.name}
                </p>)
                : null
            }
            {address.postalCode
                ? (<p className='mb-2'>
                    <strong>Postal Code:</strong> {address.postalCode}
                </p>)
                : null
            }
            {address.phoneNumber
                ? (<p className='mb-2'>
                    <strong>Phone Number:</strong> {address.phoneNumber}
                </p>)
                : null
            }
            {address.email
                ? (<p className='mb-2'>
                    <strong>E-mail Address:</strong> <a
                    href={"mailto:" + address.email}>{address.email}</a>
                </p>)
                : null
            }
        </div>
    )
}

export default Address;