import {Table} from "react-bootstrap";
import React from "react";
import {ISupport} from "../../../interfaces/support.interface";

interface IProps {
    content: ISupport[];
}

const SupportTable: React.FC<IProps> = ({ content }) => {
    if (content.length === 0) {
        return (
            <>
                <br />
                <h4>No Support Tickets Found...</h4>
                <br />
            </>
        )
    } else {
        return (
            <>
                <Table size="md" striped bordered hover responsive >
                    <thead className="thead-light">
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Active</th>
                        <th>Type</th>
                        <th>Entity</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() => window.location.href = `/support/${item.id}`}
                            style={{cursor: "pointer"}}
                        >
                            <td>{item.id}</td>
                            <td>{item.name || ""}</td>
                            <td>{item.description?.length > 25 ? item.description.substring(0, 25) + "..." : item.description || ""}</td>
                            <td>{item.active ? 'Yes' : 'No'}</td>
                            <td>{item.supportType ? item.supportType.name || "" : ""}</td>
                            <td>{item.entityType ? item.entityType.name || "" : ""}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        )
    }
}

export default SupportTable;