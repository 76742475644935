import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ISocial} from '../../../interfaces/social.interface';
import {ResponseData} from '../../../interfaces/pageable.interface';
import apiRoutes from "../../../config/apiRoutes";
import {Button, Col, Form, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import {ISearchBar} from "../../../interfaces/searchBar.interface";
import {IState, useTypesContext} from "../../../contexts/typeContexts";
import PaginationComponent from "../../utility/renderPagination";
import SocialsTable from "./SocialsTable";
import * as Yup from "yup";
import {axiosRequest, handleRequestError} from "../../../services/axios.service";
import {HttpMethods} from "../../../routes/HttpMethods";
import {useSearchParams} from "react-router-dom";

const SocialsList: React.FC = () => {
    const [data, setData] = useState<ResponseData<ISocial>>();
    const {usedCountryList, socialTypes} = useTypesContext();
    const [isLoading, setIsLoading] = useState(true);
    const [statesList, setStatesList] = useState<IState[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(0);

    const initialValues: ISearchBar = {
        type: '',
        category: '',
        country: '',
        searchText: '',
        state: '',
        company: ''
    };

    const ValidationSchema = () =>
        Yup.object().shape({
            searchText: Yup.string(),
            category: Yup.number().typeError("Value must be a number"),
            country: Yup.number().typeError("Value must be a number"),
            type: Yup.number().typeError("Value must be a number"),
            state: Yup.number().typeError("Value must be a number")
        });

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            setPageNumber(0);
            fetchHomes();
        }
    });

    const setUrlParams = () => {
        const items = new URLSearchParams();
        /*        if (formik.values.searchText) items.set("searchText", formik.values.searchText);
                if (formik.values.category) items.set("category", formik.values.category);
                if (formik.values.country) items.set("country", formik.values.country);
                if (formik.values.state) items.set("state", formik.values.state);
                if (formik.values.type) items.set("type", formik.values.type);*/
        if (pageNumber !== 0) items.set("page", String(pageNumber + 1));
        setSearchParams(items);
    };

    const fetchHomes = async () => {
        setIsLoading(true);
        const params = {
            ...Object.fromEntries(
                Object.entries(formik.values).filter(([_, value]) => value !== '')
            ),
            ...(pageNumber !== 0 ? { page: pageNumber } : {}), // Only add page if pageNumber is not 0
        };
        axiosRequest(HttpMethods.GET, apiRoutes.SOCIALS, "Social Groups", null, {params}).then(response => {
            setData(response.data);
            setUrlParams();
            setIsLoading(false);
        }).catch(error => {
            handleRequestError(error, "Social Groups");
        }).finally(() =>
            setIsLoading(false)
        );
    }

    // HOMES FETCH SCRIPT
    useEffect(() => {
        fetchHomes();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchHomes();
        // eslint-disable-next-line
    }, [pageNumber]);

    const handlePageChange = (page: number) => {
        setPageNumber(page);
    }

    const countryChangedHandler = async (countryId: number) => {
        try {
            const response = await axios.get(`${apiRoutes.STATES}/${countryId}`);
            setStatesList(response.data.sort((a: IState, b: IState) => a.name.localeCompare(b.name)));
        } catch (error) {
            setStatesList([]);
            toast.error("Error getting State list");
            console.error(error);
        }
    };

    useEffect(() => {
        if (formik.values.country !== '') {
            countryChangedHandler(Number(formik.values.country));
        }
    }, [formik.values.country]);

    if (isLoading) {
        return (
            <>
                <br />
                <h4>Social Groups Loading... </h4>
                <br />
            </>
        )
    } else {
        return (
            <div>
                <div className="p-3 mb-2">
                    <h3>Fragrance Free Social Groups</h3>

                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Row>
                            <Form.Group as={Col} xs={12} md={3} controlId="formType">
                                <Form.Label>Search by Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...formik.getFieldProps('type')}
                                    isInvalid={Boolean(formik.touched.type && formik.errors.type)}
                                >
                                    <option value="">Search by Type</option>
                                    {socialTypes.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.type}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={3} controlId="formCountry">
                                <Form.Label>Search by Country</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...formik.getFieldProps('country')}
                                    isInvalid={Boolean(formik.touched.country && formik.errors.country)}
                                >
                                    <option value="">Search by Country</option>
                                    {usedCountryList.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.country}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={3} controlId="formState">
                                <Form.Label>Search by State/Province</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...formik.getFieldProps('state')}
                                    isInvalid={Boolean(formik.touched.state && formik.errors.state)}
                                >
                                    <option value="">Select Country First</option>
                                    {statesList.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.country}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={3} controlId="formSearchText">
                                <Form.Label>Search by Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by Name"
                                    {...formik.getFieldProps('searchText')}
                                    isInvalid={Boolean(formik.touched.searchText && formik.errors.searchText)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.searchText}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={3} controlId="formSubmitButton">
                                <Button type="submit" className="mt-3 me-2" variant="primary">Search</Button>
                                <Button type="submit" className="mt-3" onClick={() => formik.resetForm()}
                                        variant="success">Reset</Button>
                            </Form.Group>

                        </Row>
                    </Form>
                </div>

                <SocialsTable content={data?.content || []}/>
                <PaginationComponent data={data} handlePageChange={handlePageChange}/>
            </div>
        );
    }
};

export default SocialsList;
