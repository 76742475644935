import {IBusiness} from "../../../interfaces/business.interface";
import { Table} from "react-bootstrap";
import StarRating from "../../utility/StarRating";
import React from "react";

interface IProps {
    content: IBusiness[];
}

const BusinessTable: React.FC<IProps> = ({ content }) => {
if (content.length === 0) {
        return (
            <>
                <br />
                <h4>No Businesses Found...</h4>
                <br />
            </>
        )
} else {
    return (
            <>
                <Table size="md" striped bordered hover responsive >
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>FF Policy</th>
                        <th>State/Province</th>
                        <th>Country</th>
                        <th>Reviews/Stars</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() => window.location.href = `/businesses/${item.id}`}
                            style={{cursor: "pointer"}}
                        >
                            <td>{item.name?.length > 65 ? item.name.substring(0, 62) + "..." : item.name || ""}</td>
                            <td>{item.businessType ? item.businessType.name || "" : ""}</td>
                            <td>{item.policy ? "Yes" : "No"}</td>
                            <td>{item.city ? item.city || "" : ""} {item.city && item.state ? ', ' : ''} {item.state ? item.state.name || "" : ""}</td>
                            <td>{item.country ? item.country.name || "" : ""}</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    {item.reviews ? `${item.reviews} / ` : ""}
                                    <StarRating rating={item.rating || 0}/>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        )
    }
}

export default BusinessTable;