import React from 'react';
import {useNavigate} from 'react-router-dom';
import SocialList from './components/SocialList';

import apiRoutes from '../../config/apiRoutes';
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {useAuth} from "../../contexts/authContext";


const SocialsListPage: React.FC = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleButtonClick = () => {
        window.location.href = apiRoutes.BASE + '/socials/0/edit';
    };

    if (currentUser) {
        return (
            <div>
                <HelmetEntityComponent title="Social Groups List" entity="Social Groups"/>
                <SocialList/>
                <button className="btn btn-success m-2" onClick={handleButtonClick}>Add a Social Group</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        );
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Social Groups List" entity="Social Groups"/>
                <SocialList/>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
                <p>Please login to add or edit Rental Homes and Reviews.</p>
            </div>
        );
    }
};

export default SocialsListPage;
