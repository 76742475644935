import React, {createContext, ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import apiRoutes from '../config/apiRoutes';
import {toast} from 'react-toastify';

export interface ICommonType {
    id: number;
    name: string;
}

export interface ICountry extends ICommonType {
    "iso3": string;
    "latitude": number;
    "longitude": number;
    "phone_code": string;
}

export interface IState extends ICommonType {
    "latitude": number;
    "longitude": number;
    "state_code": string;
}

export interface IBusinessType extends ICommonType {
}

export interface IHomeType extends ICommonType {
}

export interface IProductCategory extends ICommonType {
}

export interface IEntityType extends ICommonType {
}

export interface ISupportType extends ICommonType {
}

export interface IUserType extends ICommonType {
}

export interface ISocialType extends ICommonType {
}

export interface IFrequencyType extends ICommonType {
}

export interface IProductType extends ICommonType {
    productCategoryType: IProductCategory;
}

interface ContextState {
    countryList: ICountry[];
    usedCountryList: ICountry[];
    businessTypes: IBusinessType[];
    productTypes: IProductType[];
    productCategories: IProductCategory[];
    homeTypes: IHomeType[];
    entityTypes: IEntityType[];
    supportTypes: ISupportType[];
    userTypes: IUserType[];
    socialTypes: ISocialType[];
    frequencyTypes: IFrequencyType[];
}

type Props = {
    children: ReactNode;
};

const TypesContext = createContext<ContextState | undefined>(undefined);

export const TypesProvider: React.FC<Props> = ({ children }) => {
    const [countryList, setCountries] = useState<ICountry[]>([]);
    const [usedCountryList, setUsedCountryList] = useState<ICountry[]>([]);
    const [businessTypes, setBusinessTypes] = useState<IBusinessType[]>([]);
    const [productTypes, setProductTypes] = useState<IProductType[]>([]);
    const [productCategories, setProductCategories] = useState<IProductCategory[]>([]);
    const [homeTypes, setHomeTypes] = useState<IHomeType[]>([]);
    const [entityTypes, setEntityTypes] = useState<IEntityType[]>([]);
    const [supportTypes, setSupportTypes] = useState<ISupportType[]>([]);
    const [userTypes, setUserTypes] = useState<IUserType[]>([]);
    const [socialTypes, setSocialTypes] = useState<ISocialType[]>([]);
    const [frequencyTypes, setFrequencyTypes] = useState<IFrequencyType[]>([]);

    useEffect(() => {
        const fetchTypes = async () => {
            try {
                // Check if the data already exists in localStorage
                const cachedData = localStorage.getItem('typesData');

                if (cachedData) {
                    const parsedData = JSON.parse(cachedData);

                    // If an hour has passed since the data was last updated, fetch new data
                    const anHourAgo = Date.now() - 3600 * 1000;
                    if (parsedData.timestamp && parsedData.timestamp > anHourAgo) {
                        setCountries(parsedData.data.countryList);
                        setUsedCountryList(parsedData.data.usedCountryList);
                        setBusinessTypes(parsedData.data.businessTypes);
                        setProductTypes(parsedData.data.productTypes);
                        setProductCategories(parsedData.data.productCategories);
                        setHomeTypes(parsedData.data.homeTypes);
                        setEntityTypes(parsedData.data.entityTypes);
                        setSupportTypes(parsedData.data.supportTypes);
                        setUserTypes(parsedData.data.userTypes);
                        setSocialTypes(parsedData.data.socialTypes);
                        setFrequencyTypes(parsedData.data.frequencyTypes);
                        return;
                    }
                }

                // If data is not in localStorage or it's older than an hour, fetch it from the API
                const response = await axios.get(apiRoutes.TYPES);

                if (!response) {
                    toast.warning("Error fetching Types data");
                    return;
                }

                const typesData = {
                    countryList: response.data.countryList,
                    usedCountryList: response.data.usedCountryList,
                    businessTypes: response.data.businessTypes,
                    productTypes: response.data.productTypes,
                    productCategories: response.data.productCategories,
                    homeTypes: response.data.homeTypes,
                    entityTypes: response.data.entityTypes,
                    supportTypes: response.data.supportTypes,
                    userTypes: response.data.userTypes,
                    socialTypes: response.data.socialTypes,
                    frequencyTypes: response.data.frequencyTypes.sort(
                        (a: IFrequencyType, b: IFrequencyType) => a.id - b.id
                    ),
                };

                // Set the state and cache the data in localStorage
                setCountries(typesData.countryList);
                setUsedCountryList(typesData.usedCountryList);
                setBusinessTypes(typesData.businessTypes);
                setProductTypes(typesData.productTypes);
                setProductCategories(typesData.productCategories);
                setHomeTypes(typesData.homeTypes);
                setEntityTypes(typesData.entityTypes);
                setSupportTypes(typesData.supportTypes);
                setUserTypes(typesData.userTypes);
                setSocialTypes(typesData.socialTypes);
                setFrequencyTypes(typesData.frequencyTypes);

                localStorage.setItem(
                    'typesData',
                    JSON.stringify({ timestamp: Date.now(), data: typesData })
                );

            } catch (error) {
                console.error("Error fetching type data:", error);
            }
        };
        fetchTypes();
    }, []);

    const contextValue = useMemo(
        () => ({
            countryList,
            usedCountryList,
            businessTypes,
            productTypes,
            productCategories,
            homeTypes,
            entityTypes,
            supportTypes,
            userTypes,
            socialTypes,
            frequencyTypes,
        }),
        [
            countryList,
            usedCountryList,
            businessTypes,
            productTypes,
            productCategories,
            homeTypes,
            entityTypes,
            supportTypes,
            userTypes,
            socialTypes,
            frequencyTypes,
        ]
    );

    return <TypesContext.Provider value={contextValue}>{children}</TypesContext.Provider>;
};

export const useTypesContext = (): ContextState => {
    const context = useContext(TypesContext);
    if (!context) {
        throw new Error("useTypesContext must be used within a TypesProvider");
    }
    return context;
};