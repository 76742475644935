import React, {useEffect, useState} from 'react';
import axios from 'axios';
import apiRoutes from '../../../config/apiRoutes';
import {IHomeReview} from '../../../interfaces/home.review.interface';
import {Link} from 'react-router-dom';
import {ResponseData} from '../../../interfaces/pageable.interface';
import StarRating from '../../utility/StarRating'
import {Table} from "react-bootstrap";
import PaginationComponent from "../../utility/renderPagination";

interface HomeReviewsProps {
    homeId: number;
}

const HomeReviewList: React.FC<HomeReviewsProps> = ({homeId}) => {
    const [data, setData] = useState<ResponseData<IHomeReview>>();
    const [content, setContent] = useState<IHomeReview[]>([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<ResponseData<IHomeReview>>(apiRoutes.HOMES + `/${homeId}/reviews`);
                setData(response.data);
                setContent(response.data?.content || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [homeId]);

    const handlePageChange = async (pageNumber: number) => {
        try {
            const response = await axios.get<ResponseData<IHomeReview>>(apiRoutes.HOMES + `/${homeId}/reviews?page=${pageNumber}`);
            setData(response.data);
            setContent(response.data?.content || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    if (content.length === 0) {
        return (
            <div>
            </div>
        );
    } else {
        return (
            <div>
                <Table size="md" striped bordered hover responsive >
                    <thead className="thead-light">
                    <tr>
                        <th>Title</th>
                        <th>Comment</th>
                        <th>Rating</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() => window.location.href = `/homes/${homeId}/reviews/${item.id}`}
                            style={{cursor: "pointer"}}
                        >
                            <td>{item.name?.length > 30 ? item.name.substring(0, 27) + "..." : item.name || ""}</td>
                            <td>{item.comment?.length > 80 ? item.comment.substring(0, 77) + "..." : item.comment || ""}</td>
                            <td><StarRating rating={item.rating}/></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <PaginationComponent data={data} handlePageChange={handlePageChange} />
            </div>
        );
    }
};

export default HomeReviewList;