import React from 'react';
import {Button, Form, Modal} from 'react-bootstrap';

function ReviewFormModal(props: any) {
    const {showModal, handleCloseModal, handleAddReview, reviewForm, handleChange, formErrors} = props;


    return (
        <Modal centered show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Review Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleAddReview}>
                    <Form.Group controlId="formName">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your title"
                            name="name"
                            value={reviewForm.name}
                            onChange={handleChange}
                            isInvalid={!!formErrors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formErrors.name}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formComment">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Enter your comment"
                            rows={3}
                            name="comment"
                            value={reviewForm.comment}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formRating">
                        <Form.Label>Rating (1-5)</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter rating"
                            name="rating"
                            value={reviewForm.rating}
                            onChange={handleChange}
                            isInvalid={!!formErrors.rating}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formErrors.rating}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button className='mt-4' variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ReviewFormModal;