import React from "react";

import {IUserResetForm, IUserResetSubmit} from "../../interfaces/user.interface";
import {Link, NavigateFunction, useNavigate} from 'react-router-dom';
import apiRoutes from "../../config/apiRoutes";
import {Button, Form} from "react-bootstrap";
import * as Yup from "yup";
import {useFormik} from "formik";
import authUser from "../../services/auth.user";
import HelmetComponent from "../utility/HelmetComponent";
import {axiosRequest} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";
import {Entities} from "../../routes/Entities";

const ResetPassword: React.FC = () => {
    const navigate: NavigateFunction = useNavigate();
    const authLevel = authUser(0)
    const [message, setMessage] = React.useState("");
    const entity = Entities.PASSWORD;

    const ValidationSchema = () =>
        Yup.object().shape({
            username: Yup.string().required("Username is required"),
            email: Yup.string().required("Email address is required").email("Invalid email address"),
            newPassword: Yup.string().required("New Password is required").min(6, "Password must be at least 6 characters"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword')], 'Passwords must match')
                .required('Confirm Password is required'),
        });

    const initialValues: IUserResetForm = {
        username: '',
        email: '',
        newPassword: '',
        confirmPassword: ''
    };

    const formik= useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    function convertFormToSubmit(form: IUserResetForm): IUserResetSubmit {
        const {confirmPassword, ...rest} = form;
        return {...rest};
    }

    const handleSubmit = async (values: IUserResetForm) => {
        // FORM CONVERSION
        const updatedFormValues = convertFormToSubmit(values);
        let url;
        if (authLevel === 3) {
            url = `${apiRoutes.ADMIN_RESET}`;
        } else {
            url = `${apiRoutes.RESET}`;
        }

        axiosRequest(HttpMethods.POST, url, entity, updatedFormValues).then(response => {
            setMessage("Password updated successfully.\nYou will be logged out in 5 seconds.");
            if (authLevel !== 3) {
                setTimeout(() => {
                    navigate("/logout");
                }, 5000);
            }
        }).catch(error => {
            setMessage("Username and email do not match or Username does not exist.");
        });
    }

    return (
        <div className="col-md-12">
            <HelmetComponent
                title="Reset Password"
            />
            <div className="card card-container">
                <p style={{textAlign: 'center', width: '100%', fontSize: '2rem'}}>Reset Password</p>
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />
                <Form noValidate onSubmit={formik.handleSubmit}>

                    <Form.Group controlId="formUsername">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('username')}
                            isInvalid={Boolean(formik.touched.username && formik.errors.username)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.username}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('email')}
                            isInvalid={Boolean(formik.touched.email && formik.errors.email)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formNewPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            {...formik.getFieldProps('newPassword')}
                            isInvalid={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.newPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formNewPasswordVerify">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                            type="password"
                            {...formik.getFieldProps('confirmPassword')}
                            isInvalid={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br/>
                    <Button type="submit">Submit</Button>
                </Form>
                <br/>
                <p className="mt-3" style={{color: 'red'}}>{message}</p>

                <p className="mt-2" style={{textAlign: 'center', justifyContent: 'center'}}>
                    If you have forgotten your Username, please visit <Link to="/forgot-username">Forgotten
                    Username</Link>. <br/><br/>
                    For additional assistance email <br/>
                    <a href="mailto:fragrancefreeresources@gmail.com">fragrancefreeresources@gmail.com</a>.
                </p>

            </div>
        </div>
    );
};

export default ResetPassword;