import React from 'react';
import {useNavigate} from 'react-router-dom';
import HomesListV2 from './components/HomesListV2';

import apiRoutes from '../../config/apiRoutes';
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {useAuth} from "../../contexts/authContext";


const HomesListPage: React.FC = () => {

    const navigate = useNavigate();

    const handleGoBack = () => navigate(-1);
    const { currentUser } = useAuth();

    const handleButtonClick = () => {
        window.location.href = apiRoutes.BASE + '/homes/0/edit';
    };

    if (currentUser) {
        return (
            <div>
                <HelmetEntityComponent title="Rental Homes List" entity="Rental Homes"/>
                <HomesListV2/>
                <button className="btn btn-success m-2" onClick={handleButtonClick}>Add a Home</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        );
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Rental Homes List" entity="Rental Homes"/>
                <HomesListV2/>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
                <p>Please login to add or edit Rental Homes and Reviews.</p>

            </div>
        );
    }
};

export default HomesListPage;
