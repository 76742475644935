import React from 'react';
import {useNavigate} from 'react-router-dom';
import ProductsList from './components/ProductsList';
import apiRoutes from "../../config/apiRoutes";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {useAuth} from "../../contexts/authContext";


const ProductsListPage: React.FC = () => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };
    const { currentUser } = useAuth();

    const handleAddProduct = () => {
        window.location.href = apiRoutes.BASE + '/products/0/editV3';
    };

    if (currentUser) {
        return (
            <div>
                <HelmetEntityComponent title="Products List" entity="Products"/>
                <ProductsList/>
                <button className="btn btn-success m-2" onClick={handleAddProduct}>Add a Product</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        );
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Products List" entity="Products"/>
                <ProductsList/>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
                <p>Please login to add or edit Products and Reviews.</p>
            </div>
        );
    }
};

export default ProductsListPage;
