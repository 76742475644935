import HelmetComponent from "./utility/HelmetComponent";
import React from "react";
import {useAuth} from "../contexts/authContext";
import HelmetEntityComponent from "./utility/HelmetEntityComponent";

const Home = () => {
    const { authMessage } = useAuth();

    return (
        <div>
            <HelmetEntityComponent title="Home" entity="businesses, products, rental homes, and social groups."/>
            <p style={{color: "red", textAlign: "center"}}>{authMessage}</p>
            <blockquote>A place for people to share their knowledge and experiences with fragrance free businesses,
                products, rental homes, and social groups with the goal of helping each other.
            </blockquote>
            <h1 className="left">Fragrance</h1>
            <h1 className="right">Freedom.</h1>
        </div>
    );
};

export default Home;
