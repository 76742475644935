import React from 'react';
import HelmetComponent from "./utility/HelmetComponent";
import BusinessRulesHeader from "./businesses/components/BusinessRulesHeader";
import ProductRulesHeader from "./products/components/ProductRulesHeader";
import HomeRulesHeader from "./homes/components/HomeRulesHeader";
import SocialRulesHeader from "./socials/components/SocialRulesHeader";

const Terms: React.FC = () => {

    return (
        <div>
            <HelmetComponent title="Terms and Conditions"/>
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">Terms and Conditions</h3>

            <div className="p-4">
            <p><strong>Entries listed here are not guarantees of the safety or effectiveness of any business, product,
                rental home, or social group, and due diligence is necessary on the part of the user.</strong></p>

            <p><strong>I agree as a user to follow the following rules for adding or editing entries in this website:</strong> </p>
            <BusinessRulesHeader/>
            <ProductRulesHeader/>
            <HomeRulesHeader/>
            <SocialRulesHeader/>

    <p><strong>For additional questions, email: </strong> <a
        href="mailto:fragrancefreeresources@gmail.com">fragrancefreeresources@gmail.com</a>.</p>

            <p><strong>Legal Terms and Conditions: </strong></p>
            <p>Please read these terms and conditions ("terms and conditions", "terms") carefully before using FragranceFreeResources.com website ("website", "service") operated by Joel Strong ("us", "we", "our").</p>

            <p><strong>Conditions of use</strong></p>
            <p>By using this website, you certify that you have read and reviewed this Agreement and that you agree to
                comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to
                stop using the website accordingly. FragranceFreeResources.com only grants use and access of this website, its
                products, and its services to those who have accepted its terms.</p>
            <p><strong>Privacy policy</strong></p>
            <p>Before you continue using our website, we advise you to read our privacy policy <a href="/privacy">Privacy Policy</a> regarding our user data collection. It will help you better understand our practices.</p>
                    <p><strong>Age restriction</strong></p>
            <p>You must be at least 18 (eighteen) years of age before you can use this website. By using this website,
                        you warrant that you are at least 18 years of age and you may legally adhere to this Agreement.
                        FragranceFreeResources.com assumes no responsibility for liabilities related to age misrepresentation.</p>
            <p><strong>Intellectual property</strong></p>
            <p>You agree that all materials, products, and services provided on this website are the property of
                        FragranceFreeResources.com, its affiliates, directors, officers, employees, agents, suppliers, or licensors
                        including all copyrights, trade secrets, trademarks, patents, and other intellectual property.
                        You
                        also
                        agree that you will not reproduce or redistribute the FragranceFreeResources.com's intellectual property in
                        any
                        way,
                        including electronic, digital, or new trademark registrations.
                        You grant FragranceFreeResources.com a royalty-free and non-exclusive license to display, use, copy,
                        transmit,
                        and
                        broadcast the content you upload and publish. For issues regarding intellectual property claims,
                        you
                        should contact the company in order to come to an agreement.
                        User accounts
                        As a user of this website, you may be asked to register with us and provide private information.
                        You
                        are
                        responsible for ensuring the accuracy of this information, and you are responsible for
                        maintaining
                        the
                        safety and security of your identifying information. You are also responsible for all activities
                        that
                        occur under your account or password.
                        If you think there are any possible issues regarding the security of your account on the
                        website,
                        inform
                        us immediately so we may address them accordingly.
                        We reserve all rights to terminate accounts, edit or remove content and cancel orders at our
                        sole
                        discretion.</p>
                        <p><strong>Applicable law</strong></p>
                        <p>By using this website, you agree that the laws of the Oregon, without regard to
                            principles
                            of
                            conflict laws, will govern these terms and conditions, or any dispute of any sort that might
                            come
                            between FragranceFreeResources.com and you, or its business partners and associates.</p>
                        <p><strong>Disputes</strong></p>
                        <p>Any dispute related in any way to your use of this website or to products you purchase from
                            us
                            shall
                            be
                            arbitrated by state or federal court Oregon and you consent to exclusive
                            jurisdiction
                            and
                            venue
                            of such courts.</p>
                        <p><strong>Indemnification</strong></p>
                        <p> You agree to indemnify FragranceFreeResources.com and its affiliates and hold FragranceFreeResources.com harmless
                            against
                            legal
                            claims and demands that may arise from your use or misuse of our services. We reserve the
                            right
                            to
                            select our own legal counsel.</p>
                        <p><strong>Limitation on liability</strong></p>
                        <p>FragranceFreeResources.com is not liable for any damages that may occur to you as a result of your misuse
                            of
                            our
                            website. FragranceFreeResources.com reserves the right to edit, modify, and change this Agreement at any
                            time.
                            We
                            shall let our users know of these changes through electronic mail. This Agreement is an
                            understanding
                            between FragranceFreeResources.com and the user, and this supersedes and replaces all prior agreements
                            regarding
                            the
                            use of this website.</p>
            </div>
        </div>

)
    ;
};

export default Terms
