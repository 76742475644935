import {Table} from "react-bootstrap";
import React from "react";
import {ISocial} from "../../../interfaces/social.interface";

interface IProps {
    content: ISocial[];
}

const SocialsTable: React.FC<IProps> = ({ content }) => {
    if (content.length === 0) {
        return (
            <>
                <br/>
                <h4>No Social Groups Found...</h4>
                <br/>
            </>
        )
    } else {
        return (
            <>
                <Table size="md" striped bordered hover responsive >
                    <thead className="thead-light">
                    <tr>
                        <th>Group Name</th>
                        <th>Meeting Type</th>
                        <th>FF Policy</th>
                        <th>State/Province</th>
                        <th>Country</th>
                        <th>Active</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() => window.location.href = `/socials/${item.id}`}
                            style={{cursor: "pointer"}}
                        >
                            <td>{item.name?.length > 65 ? item.name.substring(0, 62) + "..." : item.name || ""}</td>
                            <td>{item.socialType ? item.socialType.name || "" : ""}</td>
                            <td>{item.policy ? "Yes" : "No"}</td>
                            <td>{item.city ? item.city || "" : ""} {item.city && item.state ? ', ' : ''} {item.state ? item.state.name || "" : ""}</td>
                            <td>{item.country ? item.country.name || "" : ""}</td>
                            <td>{item.active ? "Yes" : "No"}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        )
    }
}

export default SocialsTable;