import React, {useEffect, useState} from 'react';
import {IUser} from '../../interfaces/user.interface';
import apiRoutes from "../../config/apiRoutes";
import {Button, Col, Form, Row} from "react-bootstrap";
import {ResponseData} from "../../interfaces/pageable.interface";
import {useFormik} from "formik";
import {IUserBar} from "../../interfaces/searchBar.interface";
import * as Yup from "yup";
import PaginationComponent from "../utility/renderPagination";
import UsersTable from "./components/UsersTable";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";
import {useSearchParams} from "react-router-dom";

const UsersList: React.FC = () => {
    const [data, setData] = useState<ResponseData<IUser>>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const initialValues: IUserBar = {
        searchText: '',
        userId: '',
    };

    const ValidationSchema = () =>
        Yup.object().shape({
            searchText: Yup.string(),
            userId: Yup.number().typeError("Value must be a number")
        });

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            setPageNumber(0);
            fetchUsers();
        }
    });

    const setUrlParams = () => {
        const items = new URLSearchParams();
        /*        if (formik.values.searchText) items.set("searchText", formik.values.searchText);
                if (formik.values.category) items.set("category", formik.values.category);
                if (formik.values.country) items.set("country", formik.values.country);
                if (formik.values.state) items.set("state", formik.values.state);
                if (formik.values.type) items.set("type", formik.values.type);*/
        if (pageNumber !== 0) items.set("page", String(pageNumber + 1));
        setSearchParams(items);
    };

    const fetchUsers = async () => {
        setIsLoading(true);
        const params = {
            ...Object.fromEntries(
                Object.entries(formik.values).filter(([_, value]) => value !== '')
            ),
            ...(pageNumber !== 0 ? { page: pageNumber } : {}), // Only add page if pageNumber is not 0
        };
        axiosRequest(HttpMethods.GET, apiRoutes.USERS, "Users", null, {params}).then(response => {
            setData(response.data);
            setUrlParams();
            setIsLoading(false);
        }).catch(error => {
            handleRequestError(error, "Users");
        }).finally(() =>
            setIsLoading(false)
        );
    }

    // Users FETCH SCRIPT
    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line
    }, [pageNumber]);

    const handlePageChange = (page: number) => {
        setPageNumber(page);
    }

    if (isLoading) {
        return (
            <>
                <br />
                <h4>Users Loading... </h4>
                <br />
            </>
        )
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Users List" entity="Users"/>
                <div className="p-3 bg-secondary bg-gradient text-white rounded-3">
                    <h3>Users List</h3>

                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Row>

                            <Form.Group as={Col} xs={12} md={3} controlId="formSearchText">
                                <Form.Label>Search by Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by Name"
                                    {...formik.getFieldProps('searchText')}
                                    isInvalid={Boolean(formik.touched.searchText && formik.errors.searchText)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.searchText}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={3} controlId="formUserId">
                                <Form.Label>Search by Id</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by Id"
                                    {...formik.getFieldProps('userId')}
                                    isInvalid={Boolean(formik.touched.userId && formik.errors.userId)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.userId}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={3} controlId="formSubmitButton">
                                <Form.Label>{'\u200B'}</Form.Label>
                                <Button type="submit" className="me-2" variant="primary">Search</Button>
                                <Button type="submit" onClick={() => formik.resetForm()}
                                        variant="success">Reset</Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>

                <UsersTable content={data?.content || []}/>
                <PaginationComponent data={data} handlePageChange={handlePageChange}/>
            </div>
        );
    }
};

export default UsersList;
