import React from 'react';
import HelmetComponent from "./utility/HelmetComponent";

const Privacy: React.FC = () => {

    return (
        <div>
            <HelmetComponent title="Privacy Policy" />
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">Privacy Policy</h3>
            <div>
                <p>This website is not used by a business and does not make any profit. This privacy policy will explain
                    how this website uses the personal data we collect from you when you use this website. This privacy
                    statement is written to comply with GDPR and uses a template provided by <a
                        href="https://gdpr.eu/privacy-notice/">https://gdpr.eu/privacy-notice/</a>.</p>

                <p><strong>What data do we collect?</strong></p>
                <p>Personal Identification Information (Name, home address, email address, phone number and website.</p>

                <p><strong>How do we collect your data?</strong></p>
                <ul>
                    <li>When you register online for an account.</li>
                    <li>When you voluntarily add information to your user profile.</li>
                </ul>

                <p><strong>How will we use your data?</strong></p>
                <ul>
                    <li>To display businesses, social groups, and rental homes closest to you. It will send anonymous address
                        information to a third party to determine your latitude and longitude to offer these features.
                    </li>
                    <li>To send emails about support tickets or moderation requests.</li>
                </ul>
                <p>This website does NOT share any identifiable information with any other party.</p>

                <p><strong>How do we store your data?</strong></p>
                <ul>
                    <li>Website data is securely stored using Amazon Web Services</li>
                    <li>This website uses https to encrypt communications between the website and the server.</li>
                    <li>This website uses HttpOnly cookies with strict secure usage to secure access using JWTs.</li>
                    <li>This website will retain all information unless actively deleted by a user or for compliance.
                    </li>
                </ul>

                <p><strong>Marketing</strong></p>
                <p>This website does not use any information provided to sell, promote, or profit to any users.</p>


                <p><strong>What are your data protection rights?</strong></p>
                <ul>
                    <li><strong>The right to access </strong>- You have the right to request copies of your personal
                        data.
                    </li>
                    <li><strong>The right to rectification </strong> - You have the right to request that this website
                        correct any information you believe is inaccurate or incomplete.
                    </li>
                    <li><strong>The right to erasure </strong> - You have the right to request that this website erase
                        your personal data.
                    </li>
                    <li><strong>The right to restrict processing </strong> - You have the right to object to this
                        website processing your personal data, under certain conditions.
                    </li>
                    <li><strong>The right to object to processing </strong> - You have the right to object to this
                        websites processing of your personal data, under certain conditions.
                    </li>
                    <li><strong>The right to data portability </strong> - You have the right to request that this
                        website transfer the data we have collected to another organization, or directly to you, under
                        certain conditions.
                    </li>
                </ul>
                <p>If you make a request, we have one month to respond to you. If you would like to exercise any of
                    these rights, please contact us at our email: <a
                        href="mailto:fragrancefreeresources@gmail.com">fragrancefreeresources@gmail.com</a></p>

                <p><strong>What are cookies?</strong></p>
                <p>Cookes are text files place on your computer to collect standard Internet log information and visitor
                    behavior information. When you visit our website, we may collect information from you automatically
                    through cookies or similar technology.</p>
                <p>For further information, visit <a
                    href="https://allaboutcookies.org">allaboutcookies.org</a>.</p>

                <p><strong>How do we use cookies?</strong></p>
                <p>To keep you logged into this website. To remove the cookies, select the Logout link and the cookie will be deleted.</p>

                <p><strong>What types of cookies do we use?</strong></p>
                <p>Functional cookies - This website uses these cookies to recognize you on our website and remember
                    your previously selected preferences. These could include what language you prefer and location you
                    are in. Our cookie is first-party and does not use any third-party cookies.</p>

                <p><strong>How to manage cookies?</strong></p>
                <p>You can set your browser not to accept cookies, and the above website tells you how to remove cookies
                    from your browser. However, some of our website features my not function as a result.</p>

                <p><strong>Privacy policies of other websites.</strong></p>
                <p>This website contains links to other websites. Our privacy policy applies only to our website, so if
                    you click on a link to another website, you should read their privacy policy.</p>

                <p><strong>Changes to our privacy policy.</strong></p>
                <p>This website keeps its privacy policy under regular review and places any updates on this web page.
                    This privacy policy was last updated on 1 October, 2024</p>

                <p><strong>How to contact us.</strong></p>
                <p>If you have any questions about this websites privacy policy, the data we hold on you, or you would
                    like to exercise on of your data protection rights, please contact us at our email: <a
                        href="mailto:fragrancefreeresources@gmail.com">fragrancefreeresources@gmail.com</a></p>

                <p><strong>How to contact the appropriate authority.</strong></p>
                <p>Should you wish to report a complaint or if you feel that this website has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner's Office of the EU.</p>
            </div>
        </div>

    );
};

export default Privacy