export enum Entities {
    HOMES = 'Rental Homes',
    SOCIALS = 'Social Groups',
    BUSINESSES = 'Businesses',
    PRODUCTS = 'Products',
    USERS = 'Users',
    HOME = 'Rental Home',
    SOCIAL = 'Social Group',
    BUSINESS = 'Business',
    PRODUCT = 'Product',
    USER = 'User',
    PASSWORD = 'Password'
}