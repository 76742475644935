import React from 'react';
import {Helmet} from "react-helmet";

type HelmetComponentProps = {
    entity: string;
    title: string;
}

const HelmetComponent: React.FC<HelmetComponentProps> = ({entity, title}) => {
    const canonicalUrl = window.location.href;
    return (
        <>
            <Helmet>
                <title>{title} - FragranceFreeResources.com</title>
                <meta name="description" content={"A place to share and review fragrance free " + entity} />
                <link rel="canonical" href={canonicalUrl}/>
            </Helmet>
        </>
    )
}

export default HelmetComponent