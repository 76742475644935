import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import apiRoutes from "../../config/apiRoutes";
import {useTypesContext} from "../../contexts/typeContexts";
import {ISupportEdit, ISupportSubmit} from "../../interfaces/support.interface";
import * as Yup from "yup";
import {useFormik} from "formik";
import {replaceNullsWithEmptyString} from "../utility/replaceNullsWithEmptyString";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";

const SupportEdit = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {entityTypes, supportTypes} = useTypesContext();
    const handleGoBack = () => {navigate(-1);};
    const [isLoading, setIsLoading] = useState(true);

    const ValidationSchema = () =>
        Yup.object().shape({
            name: Yup.string().required('Name is required'),
            supportType: Yup.string().min(1, 'Support Type is required')
        });

    const initialValues: ISupportEdit = {
        id: '',
        name: "",
        description: "",
        entityId: '',
        entityType: '',
        supportType: '',
        active: true,
        resolution: "",
        email: false
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    const fetchSupport = async () => {
        axiosRequest(HttpMethods.GET, `${apiRoutes.SUPPORT}/${id}`, "Support Ticket").then(response => {
            // make sure response is null safe
            const data = replaceNullsWithEmptyString(response.data);
            // set values
            formik.setValues({
                ...data,
                'entityType': data.entityType?.id ?? '',
                'supportType': data.supportType?.id ?? ''
            });
        }).catch(error => {
            handleRequestError(error, "Support Ticket");
        }).finally(() => {
            setIsLoading(false)
        });
    }

    // SUPPORT FETCH SCRIPT
    useEffect(() => {
        fetchSupport();
    }, [id]);

    function convertFormToSubmit(form: ISupportEdit): ISupportSubmit {
        return {
            ...form,
            entityType: form.entityType === '' ? null : {'id': Number(form.entityType)},
            supportType: form.supportType === '' ? null : {'id': Number(form.supportType)},
        }
    }

    const handleSubmit = async (values: ISupportEdit) => {
        const updatedFormValues = convertFormToSubmit(values);

        axiosRequest(HttpMethods.PUT, `${apiRoutes.SUPPORT}/${id}`, "Support Ticket", updatedFormValues).then(response => {
            navigate(`/support/${id}`);
        }).catch(error => {
            handleRequestError(error, "Support Ticket");
        });
    }

    if (isLoading) {
        return (
            <div>
                <HelmetEntityComponent title="Support Ticket Edit" entity="Support Ticket"/>
                <h1 className='mb-3'>Add a Support Ticket</h1>
                <h2>Page loading... </h2>
            </div>
        )
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Support Ticket Edit" entity="Support Ticket"/>
                <h1 className='mb-3'>Edit a Support Ticket</h1>

                <Form noValidate onSubmit={formik.handleSubmit}>

                    <Form.Group controlId="formName">
                        <Form.Label>Ticket Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('name')}
                            isInvalid={Boolean(formik.touched.name && formik.errors.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>Ticket Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('description')}
                            isInvalid={Boolean(formik.touched.description && formik.errors.description)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEntityType">
                        <Form.Label>Entity Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('entityType')}
                            isInvalid={Boolean(formik.touched.entityType && formik.errors.entityType)}
                        >
                            <option value="">Select an Entity Type</option>
                            {entityTypes.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.entityType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formSupportType">
                        <Form.Label>Support Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('supportType')}
                            isInvalid={Boolean(formik.touched.supportType && formik.errors.supportType)}
                        >
                            <option value="">Select a Support Type</option>
                            {supportTypes.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.supportType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEntityId">
                        <Form.Label>Entity Id</Form.Label>
                        <Form.Control
                            type="number"
                            {...formik.getFieldProps('entityId')}
                            isInvalid={Boolean(formik.touched.entityId && formik.errors.entityId)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.entityId}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formResolution">
                        <Form.Label>Resolution</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('resolution')}
                            isInvalid={Boolean(formik.touched.resolution && formik.errors.resolution)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.resolution}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formActive">
                        <Form.Label>Active</Form.Label>
                        <Form.Check
                            type="switch"
                            id="active"
                            checked={formik.values.active}
                            onChange={formik.handleChange}
                            name="active"
                            isInvalid={Boolean(formik.touched.active && formik.errors.active)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.active}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Send Me Email Updates on this Ticket</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={Boolean(formik.touched.email && formik.errors.email)}
                            name='email'
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br/>
                    <Button type="submit">Submit</Button>
                    <Button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</Button>

                </Form>
            </div>
        );
    }
}

export default SupportEdit;
