export default function authUser(entityId: number) {
    const userStr = localStorage.getItem("user");
    let user = null;
    if (userStr)
        user = JSON.parse(userStr);

    if (!user) {
        return 0;
    } else if (user.roles.includes("ROLE_ADMIN")) {
        return 3;
    } else if (user.id === entityId || user.roles.includes("ROLE_MODERATOR") || user.roles.includes("ROLE_ADMIN")) {
        return 2;
    } else {
        return 1;
    }
}