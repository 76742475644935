import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import apiRoutes from "../../config/apiRoutes";
import ProductRulesHeader from "./components/ProductRulesHeader";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Button, Form} from 'react-bootstrap';
import {
    IProductExistingCompanySubmit,
    IProductFormV3,
    IProductNewCompanySubmit,
} from "../../interfaces/products.interface";
import {IProductType, IState, useTypesContext} from "../../contexts/typeContexts";
import {ICompany} from "../../interfaces/companies.interface";
import axios from "axios";
import {toast} from "react-toastify";
import {replaceNullsWithEmptyString} from "../utility/replaceNullsWithEmptyString";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {HttpMethods} from "../../routes/HttpMethods";
import {axiosRequest, handleRequestError} from "../../services/axios.service";

const ProductEditFormV3: React.FC = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const pageTitle: string = id === '0' ? "Add Product" : "Edit Product";
    const {countryList, productTypes, productCategories} = useTypesContext();
    const [statesList, setStatesList] = React.useState<IState[]>([])
    const [typeList, setTypeList] = React.useState<IProductType[]>([])
    const [companyList, setCompanyList] = React.useState<ICompany[]>([])
    const [isLoading, setIsLoading] = React.useState(true);
    const handleGoBack = () => { navigate(-1);};

    const ValidationSchema = Yup.object({
            newCompany: Yup.boolean(),
            name: Yup.string().required('Name is required').max(255, 'Maximum of 255 characters allowed'),
            description: Yup.string().required('Description is required').max(1024, 'Maximum of 1024 characters allowed'),
            productCategoryType: Yup.string().required('Category is required'),
            productType: Yup.string().required('Type is required - select Category first'),
            companyUpdate: Yup.string().required('Company Option is required'),
            email: Yup.string().email('Invalid email'),
            manufacturerLink: Yup.string().required("Product website link required."),
            companyEntity: Yup.object ({
                id: Yup.string().when("$companyUpdate", {
                    is: (value: string) => value === "existing" || value === "update",
                    then: (schema) => schema.required("Company is required"),
                    otherwise: (schema) => schema.notRequired()
                }),
                name: Yup.string().when("$companyUpdate", {
                    is: (value: string) => value === "new" || value === "update",
                    then: (schema) => schema.required("Company Name is required"),
                    otherwise: (schema) => schema.notRequired()
                }),
                country: Yup.string().when("$companyUpdate", {
                    is: (value: string) => value === "new" || value === "update",
                    then: (schema) => schema.required("Country is required"),
                    otherwise: (schema) => schema.notRequired()
                }),
                website: Yup.string().when("$companyUpdate", {
                    is: (value: string) => value === "new" || value === "update",
                    then: (schema) => schema.required("Company Website is required"),
                    otherwise: (schema) => schema.notRequired()
                }),
            }),
        });

    const initialValues: IProductFormV3 = {
        name: '',
        description: '',
        productCategoryType: '',
        productType: '',
        manufacturerLink: '',
        companyUpdate: "existing",
        companyEntity: {
            id: '',
            name: '',
            address1: '',
            address2: '',
            city: '',
            country: '',
            state: '',
            postalCode: '',
            phoneNumber: '',
            email: '',
            website: '',
            policy: false,
            policyDescription: '',
        },
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    const updateTypes = () => {
        setTypeList(productTypes.filter(typesList =>
            typesList.productCategoryType.id === Number(formik.values.productCategoryType)));
    }

    // SUBMISSION SCRIPTS
    function convertFormToSubmitExisting(form: IProductFormV3): IProductExistingCompanySubmit {
        return {
            ...form,
            productType: form.productType === '' ? null : {'id': Number(form.productType)},
            productCategoryType: form.productCategoryType === '' ? null : {'id': Number(form.productCategoryType)},
            'companyEntity': {
                ...form.companyEntity,
                id: Number(form.companyEntity.id),
                country: form.companyEntity.country === '' ? null : {'id': Number(form.companyEntity.country)},
                state: form.companyEntity.state === '' ? null : {'id': Number(form.companyEntity.state)}
            }
        }
    }

    function convertFormToSubmitNew(form: IProductFormV3): IProductNewCompanySubmit {
        return {
            ...form,
            productType: {'id': Number(form.productType)},
            productCategoryType: {'id': Number(form.productCategoryType)},
            'companyEntity': {
                ...form.companyEntity,
                country: form.companyEntity.country === '' ? null : {'id': Number(form.companyEntity.country)},
                state: form.companyEntity.state === '' ? null : {'id': Number(form.companyEntity.state)}
            }
        };
    }

    const handleSubmit = async (values: IProductFormV3) => {
        // FORM CONVERSION
        let updatedFormValues;
        if (values.companyUpdate === 'new') {
            updatedFormValues = convertFormToSubmitNew(formik.values);
        } else {
            updatedFormValues = convertFormToSubmitExisting(formik.values);
        }

        let url = id !== '0' ? `${apiRoutes.PRODUCTS}/${id}` :`${apiRoutes.PRODUCTS}`;
        let method = id !== '0' ? HttpMethods.PUT : HttpMethods.POST;


        axiosRequest(method, url, "Product", updatedFormValues).then(response => {
            navigate(`/products/${response.data.id}`);
        }).catch(error => {
            handleRequestError(error, "Product");
        });
    }

    // FETCH COMPANY LIST ON FIRST RENDER
    useEffect(() => {
        const fetchCompanies = async () => {
            axios.get(apiRoutes.COMPANIES)
                .then(response => {
                    if (response.data.length > 1) {
                        setCompanyList(response.data);
                    } else {
                        setCompanyList(response.data);
                    }
                })
                .catch(error => {
                    toast.error("Error getting Companies list")
                    setCompanyList([]);
                    console.error(error);
                });
        }
        fetchCompanies();
    }, []);

    const updateStatesHandler = async (countryId: number) => {
        try {
            const response = await axios.get(`${apiRoutes.STATES}/${countryId}`);
            setStatesList(response.data.sort((a: IState, b: IState) => a.name.localeCompare(b.name)));
        } catch (error) {
            setStatesList([]);
            toast.error("Error getting State list");
            console.error(error);
        }
    };

    const updateCountryHandler = (countryId: number) => {
        const matchedCompany =
            companyList.find(company => company.id === countryId);

        if (matchedCompany) {
            formik.setValues(prevValues => ({
                ...prevValues,
                companyEntity: {
                    ...prevValues.companyEntity,
                    ...matchedCompany,
                    id: String(matchedCompany.id),
                    country: String(matchedCompany.country?.id ?? ''),
                    state: String(matchedCompany.state?.id ?? '')
                },
                companyUpdate: "existing"
            }));
        }
    }

    useEffect(() => {
        if (formik.values.companyEntity.country !== '') {
            const countryId = Number(formik.values.companyEntity.country);
            updateStatesHandler(countryId);
            updateCountryHandler(countryId);
        }
        // eslint-disable-next-line
    }, [formik.values.companyEntity.country]);

    useEffect(() => {
        if (formik.values.companyUpdate === "new") {
            formik.setValues(prevValues => ({
                ...prevValues,
                companyEntity: {
                    id: '',
                    name: '',
                    address1: '',
                    address2: '',
                    city: '',
                    country: '',
                    state: '',
                    postalCode: '',
                    phoneNumber: '',
                    email: '',
                    website: '',
                    policy: false,
                    policyDescription: ''
                },
            }));
        }
        // eslint-disable-next-line
    }, [formik.values.companyUpdate]);

    useEffect(() => {
        const fetchProduct = async () => {
            if (id !== '0') {
                // eslint-disable-next-line
                const response = axios
                    .get(`${apiRoutes.PRODUCTS}/${id}`)
                    .then(response => {
                        // make sure response is null safe
                        const data = replaceNullsWithEmptyString(response.data);
                        // set values
                        formik.setValues({
                            ...data,
                            'productCategoryType': data.productType?.productCategoryType?.id ?? '',
                            'productType': data.productType?.id ?? '',
                            'companyEntity': {
                                ...data.companyEntity,
                                'country': data.companyEntity.country?.id ?? '',
                                'state': data.companyEntity.state?.id ?? '',
                            }
                        });
                    })
                    .catch(error => toast.error("Error getting product information"))
                    .finally(() => {
                        setIsLoading(false)
                        formik.setFieldValue('companyUpdate', 'existing');
                    });
            } else {
                setIsLoading(false);
            }
        }
        fetchProduct()
        // eslint-disable-next-line
    }, [id]);

/*    useEffect(() => {
        console.log(formik.values);
    }, [formik.values]);*/

    useEffect(() => {
        updateTypes();
        // eslint-disable-next-line
    }, [formik.values.productCategoryType, productCategories]);

    if (isLoading) {
        return (
            <div>
                <HelmetEntityComponent title="Product Edit" entity="Product"/>
                <h1 className='mb-3'>{pageTitle}</h1>
                <ProductRulesHeader/>
                <h2>Page loading... </h2>
            </div>
        )
    } else if (formik.values.companyUpdate === "existing") {
        return (
            <div>
                <HelmetEntityComponent title="Product Edit" entity="Product"/>
                <h1 className='mb-3'>{pageTitle}</h1>
                <ProductRulesHeader/>

                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('name')}
                            isInvalid={Boolean(formik.touched.name && formik.errors.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>Product Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('description')}
                            isInvalid={Boolean(formik.touched.description && formik.errors.description)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formManufacturerLink">
                        <Form.Label>Product Website Link - Please use the manufacturer website link for the specific product for easy access to ingredient and current product photos.</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('manufacturerLink')}
                            isInvalid={Boolean(formik.touched.manufacturerLink && formik.errors.manufacturerLink)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.manufacturerLink}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formProductCategory">
                        <Form.Label>Product Category</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('productCategoryType')}
                            isInvalid={Boolean(formik.touched.productCategoryType && formik.errors.productCategoryType)}
                        >
                            <option value="">Select a Category</option>
                            {productCategories.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.productCategoryType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formProductType">
                        <Form.Label>Product Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('productType')}
                            isInvalid={Boolean(formik.touched.productType && formik.errors.productType)}
                        >
                            <option value="">Select a Type</option>
                            {typeList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.productType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <h3>Company Information</h3>

                    <Form.Group controlId="formCompanyId">
                        <Form.Label>Choose a Company Option</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('companyUpdate')}
                            isInvalid={Boolean(formik.touched.companyUpdate && formik.errors.companyUpdate)}
                        >
                            <option value="existing">Use Existing Company</option>
                            <option value="new">Create NEW Company</option>
                            <option value="update">Update Existing Company</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyUpdate}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCompanyId">
                        <Form.Label>Choose an Existing Company</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('companyEntity.id')}
                            isInvalid={Boolean(formik.touched.companyEntity?.id && formik.errors.companyEntity?.id)}
                        >
                            <option value="">Select a Company</option>
                            {companyList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.id}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br/>
                    <Button type="submit">Submit</Button>
                    <Button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</Button>
                </Form>
            </div>
        );
    } else if (formik.values.companyUpdate === "new") {
        return (
            <div>
                <h1 className='mb-3'>{pageTitle}</h1>
                <ProductRulesHeader/>

                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('name')}
                            isInvalid={Boolean(formik.touched.name && formik.errors.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>Product Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('description')}
                            isInvalid={Boolean(formik.touched.description && formik.errors.description)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formManufacturerLink">
                        <Form.Label>Product Website Link</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('manufacturerLink')}
                            isInvalid={Boolean(formik.touched.manufacturerLink && formik.errors.manufacturerLink)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.manufacturerLink}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formProductCategory">
                        <Form.Label>Product Category</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('productCategoryType')}
                            isInvalid={Boolean(formik.touched.productCategoryType && formik.errors.productCategoryType)}
                        >
                            <option value="">Select a Category</option>
                            {productCategories.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.productCategoryType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formProductType">
                        <Form.Label>Product Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('productType')}
                            isInvalid={Boolean(formik.touched.productType && formik.errors.productType)}
                        >
                            <option value="">Select a Category</option>
                            {typeList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.productType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <h3>Company Information</h3>

                    <Form.Group controlId="formCompanyUpdate">
                        <Form.Label>Choose a Company Option</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('companyUpdate')}
                            isInvalid={Boolean(formik.touched.companyUpdate && formik.errors.companyUpdate)}
                        >
                            <option value="existing">Use Existing Company</option>
                            <option value="new">Create NEW Company</option>
                            <option value="update">Update Existing Company</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyUpdate}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCompanyName">
                        <Form.Label>NEW Company Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('companyEntity.name')}
                            isInvalid={Boolean(formik.touched.companyEntity?.name && formik.errors.companyEntity?.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCountryId">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('companyEntity.country')}
                            isInvalid={Boolean(formik.touched.companyEntity?.country && formik.errors.companyEntity?.country)}
                        >
                            <option value="">Select a Country</option>
                            {countryList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.country}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formStateId">
                        <Form.Label>State/Province</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('companyEntity.state')}
                            isInvalid={Boolean(formik.touched.companyEntity?.state && formik.errors.companyEntity?.state)}
                        >
                            <option value="">Select a State/Province</option>
                            {statesList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.state}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPostalCode">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('companyEntity.postalCode')}
                            isInvalid={Boolean(formik.touched.companyEntity?.postalCode && formik.errors.companyEntity?.postalCode)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.postalCode}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('companyEntity.phoneNumber')}
                            isInvalid={Boolean(formik.touched.companyEntity?.phoneNumber && formik.errors.companyEntity?.phoneNumber)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.phoneNumber}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('companyEntity.email')}
                            isInvalid={Boolean(formik.touched.companyEntity?.email && formik.errors.companyEntity?.email)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formWebsite">
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('companyEntity.website')}
                            isInvalid={Boolean(formik.touched.companyEntity?.website && formik.errors.companyEntity?.website)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.website}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPolicy">
                        <Form.Label>Fragrance Free Policy</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={formik.values.companyEntity?.policy}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={Boolean(formik.touched.companyEntity?.policy && formik.errors.companyEntity?.policy)}
                            name='policy'
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.policy}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPolicyDescription">
                        <Form.Label>Fragrance Free Policy Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('policyDescription')}
                            isInvalid={Boolean(formik.touched.companyEntity?.policyDescription && formik.errors.companyEntity?.policyDescription)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.policyDescription}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <Button type="submit">Submit</Button>
                    <Button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</Button>
                </Form>
            </div>
        );
    } else {
        return (
            <div>
                <h1 className='mb-3'>{pageTitle}</h1>
                <ProductRulesHeader/>

                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('name')}
                            isInvalid={Boolean(formik.touched.name && formik.errors.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>Product Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('description')}
                            isInvalid={Boolean(formik.touched.description && formik.errors.description)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formManufacturerLink">
                        <Form.Label>Product Website Link</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('manufacturerLink')}
                            isInvalid={Boolean(formik.touched.manufacturerLink && formik.errors.manufacturerLink)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.manufacturerLink}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formProductCategory">
                        <Form.Label>Product Category</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('productCategoryType')}
                            isInvalid={Boolean(formik.touched.productCategoryType && formik.errors.productCategoryType)}
                        >
                            <option value="">Select a Category</option>
                            {productCategories.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.productCategoryType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formProductType">
                        <Form.Label>Product Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('productType')}
                            isInvalid={Boolean(formik.touched.productType && formik.errors.productType)}
                        >
                            <option value="">Select a Category</option>
                            {typeList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.productType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <h3>Company Information</h3>

                    <Form.Group controlId="formCompanyId">
                        <Form.Label>Choose a Company Option</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('companyUpdate')}
                            isInvalid={Boolean(formik.touched.companyUpdate && formik.errors.companyUpdate)}
                        >
                            <option value="existing">Use Existing Company</option>
                            <option value="new">Create NEW Company</option>
                            <option value="update">Update Existing Company</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyUpdate}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCompanyId">
                        <Form.Label>Choose a Company to Update:</Form.Label>
                        <Form.Control
                            as="select"
                            className="red-border-select"
                            {...formik.getFieldProps('companyEntity.id')}
                            isInvalid={Boolean(formik.touched.companyEntity?.id && formik.errors.companyEntity?.id)}
                        >
                            <option value="">Select a Company</option>
                            {companyList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.id}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCompanyName">
                        <Form.Label>NEW Company Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('companyEntity.name')}
                            isInvalid={Boolean(formik.touched.companyEntity?.name && formik.errors.companyEntity?.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCountryId">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('companyEntity.country')}
                            isInvalid={Boolean(formik.touched.companyEntity?.country && formik.errors.companyEntity?.country)}
                        >
                            <option value="">Select a Country</option>
                            {countryList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.country}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formStateId">
                        <Form.Label>State/Province</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('companyEntity.state')}
                            isInvalid={Boolean(formik.touched.companyEntity?.state && formik.errors.companyEntity?.state)}
                        >
                            <option value="">Select a State/Province</option>
                            {statesList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.state}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPostalCode">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('companyEntity.postalCode')}
                            isInvalid={Boolean(formik.touched.companyEntity?.postalCode && formik.errors.companyEntity?.postalCode)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.postalCode}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('companyEntity.phoneNumber')}
                            isInvalid={Boolean(formik.touched.companyEntity?.phoneNumber && formik.errors.companyEntity?.phoneNumber)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.phoneNumber}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('companyEntity.email')}
                            isInvalid={Boolean(formik.touched.companyEntity?.email && formik.errors.companyEntity?.email)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formWebsite">
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('companyEntity.website')}
                            isInvalid={Boolean(formik.touched.companyEntity?.website && formik.errors.companyEntity?.website)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.website}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPolicy">
                        <Form.Label>Fragrance Free Policy</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={formik.values.companyEntity.policy}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={Boolean(formik.touched.companyEntity?.policy && formik.errors.companyEntity?.policy)}
                            name='companyEntity.policy'
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.policy}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPolicyDescription">
                        <Form.Label>Fragrance Free Policy Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('companyEntity.policyDescription')}
                            isInvalid={Boolean(formik.touched.companyEntity?.policyDescription && formik.errors.companyEntity?.policyDescription)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.companyEntity?.policyDescription}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <Button type="submit">Submit</Button>
                    <Button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</Button>
                </Form>
            </div>
        );
    }
};

export default ProductEditFormV3;