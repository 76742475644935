import React from 'react';
import {Button, Modal} from 'react-bootstrap';

interface Props {
    onConfirm: () => void;
    confirmText?: string;
    cancelText?: string;
    messageText?: string;
    children: React.ReactNode; // Children component
}

const Verify = ({onConfirm, confirmText = 'ClearData', cancelText = 'Cancel', messageText = 'Are you sure you want to proceed? This action cannot be undone.', children}: Props) => {
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <div onClick={handleShow}>
                {children}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> User Confirmation Needed </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {messageText}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {cancelText}
                    </Button>
                    <Button variant="danger" onClick={() => {onConfirm(); handleClose();}}>
                        {confirmText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Verify;