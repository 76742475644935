import React from 'react';
import {IHome} from '../../../interfaces/homes.interface';
import Address from "../../utility/Address";
import HomeReviewList from "../../reviews/components/HomeReviewList";

interface HomeProps {
    home: IHome;
}

const Home: React.FC<HomeProps> = ({home}) => {

    return (
        <div>
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">{home.name}</h3>
            <h4>Rental Home Information</h4>
            <div className="ml-3">
                <p className='mb-2'><strong>Home Name: </strong>{home.name || ""}</p>
                <p className='mb-2'><strong>Home Type:</strong> {home.homeType ? home.homeType.name || "" : ""}</p>
                <p className='mb-2'><strong>Description:</strong> {home.description || ""}</p>
                <p className='mb-2'><strong>Maximum People:</strong> {home.people ? home.people || "" : ""}</p>
                <p className='mb-2'><strong>Bedrooms:</strong> {home.bedrooms ? home.bedrooms || "" : ""}</p>
                <p className='mb-2'><strong>Bathrooms:</strong> {home.bathrooms ? home.bathrooms || "" : ""}</p>
                <p className='mb-2'><strong>Pets Allowed:</strong> {home.petsAllowed ? "Yes" : "No"}</p>
                <p className='mb-2'><strong>Fragrance Free Policy:</strong> {home.policy ? "Yes" : "No"}</p>
                <p className='mb-2'><strong>Fragrance Free Policy
                    Description:</strong> {home.policyDescription || ""}</p>
                <p className="mt-3 mb-0 font-weight-bold text-success font-italic">Please click the link below to view
                    Home information and photographs</p>
                <p className='mb-2'><strong>Website: </strong>
                    <a href={home.website || "#"}
                       onClick={e => {
                           e.preventDefault();
                           if (home.website) window.open(home.website, "_blank")
                       }}>
                        {home.website || ""}
                    </a></p>
                <br />
                <h4 className='mb-2'>Rental Home Address:</h4>
                <Address
                    address1={home.address1}
                    address2={home.address2}
                    city={home.city}
                    state={home.state}
                    country={home.country}
                    postalCode={home.postalCode}
                    phoneNumber={home.phoneNumber}
                    email={home.email}
                />
                <br />
                <h4 className='mb-2'>Rental Home Reviews:</h4>
                <p className='mb-2'><strong>Number of Reviews: </strong>{home.reviews} </p>
                <p className='mb-2'><strong>Rating (out of 5): </strong>{home.rating.toFixed(2)} </p>
                <HomeReviewList homeId={Number(home.id)}/>


                <p className="mt-3 mb-0 text-muted font-italic">Home Id: {home.id || ""}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Created by
                    Id: {home.createdById ? home.createdById || "" : "None"} on {
                        home.createdDate ? new Date(home.createdDate).toLocaleDateString() : "None"}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Modified by
                    Id: {home.modifiedById ? home.modifiedById || "" : "None"} on {
                        home.modifiedDate ? new Date(home.modifiedDate).toLocaleDateString() : "None"}</p>

            </div>

        </div>
    );

};

export default Home;