import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {IProductReview} from '../../../interfaces/product.review.interface';
import {Link} from 'react-router-dom';
import {ResponseData} from '../../../interfaces/pageable.interface';
import StarRating from '../../utility/StarRating';
import apiRoutes from "../../../config/apiRoutes";
import {Table} from "react-bootstrap";
import {toast} from 'react-toastify';
import PaginationComponent from "../../utility/renderPagination";

interface ProductReviewsProps {
    productId: number;
}

const ProductReviewList: React.FC<ProductReviewsProps> = ({productId}) => {
    const [data, setData] = useState<ResponseData<IProductReview>>();
    const [content, setContent] = useState<IProductReview[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<ResponseData<IProductReview>>(apiRoutes.PRODUCTS + `/${productId}/reviews`);
                setData(response.data);
                setContent(response.data?.content || []);
            } catch (error) {
                toast.error("Error getting Product Review Information")
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [productId]);

    const handlePageChange = async (pageNumber: number) => {
        try {
            const response = await axios.get<ResponseData<IProductReview>>(apiRoutes.PRODUCTS + `/${productId}/reviews?page=${pageNumber}`);
            setData(response.data);
            setContent(response.data?.content || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    if (content.length === 0) {
        return (
            <div>
            </div>
        );
    } else {
        return (
            <div>
                <Table size="md" striped bordered hover responsive >
                    <thead className="thead-light">
                    <tr>
                        <th>Title</th>
                        <th>Comment</th>
                        <th>Rating</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() => window.location.href = `/products/${productId}/reviews/${item.id}`}
                            style={{cursor: "pointer"}}
                        >
                            <td>{item.name?.length > 30 ? item.name.substring(0, 27) + "..." : item.name || ""}</td>
                            <td>{item.comment?.length > 80 ? item.comment.substring(0, 77) + "..." : item.comment || ""}</td>
                            <td><StarRating rating={item.rating}/></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <PaginationComponent data={data} handlePageChange={handlePageChange} />
            </div>
        );
    }
}

export default ProductReviewList;
