import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import apiRoutes from "../../config/apiRoutes";
import {IState, useTypesContext} from "../../contexts/typeContexts";
import {ISocialForm, ISocialSubmit} from "../../interfaces/social.interface";
import SocialRulesHeader from "./components/SocialRulesHeader";
import {toast} from 'react-toastify';
import * as Yup from "yup";
import {useFormik} from "formik";
import {replaceNullsWithEmptyString} from "../utility/replaceNullsWithEmptyString";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {HttpMethods} from "../../routes/HttpMethods";
import {axiosRequest, handleRequestError} from "../../services/axios.service";

const SocialEditForm = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {countryList, socialTypes, frequencyTypes} = useTypesContext();
    const [statesList, setStatesList] = useState<IState[]>([]);
    const pageTitle: string = id === '0' ? "Add a Social Group" : "Edit Social Group";
    const [isLoading, setIsLoading] = useState(true);
    const handleGoBack = () => { navigate(-1);};

    const ValidationSchema = () =>
        Yup.object().shape({
            name: Yup.string().required('Name is required').max(255, 'Maximum of 255 characters allowed'),
            description: Yup.string().required('Description is required').max(1024, 'Maximum of 1024 characters allowed'),
            socialType: Yup.string().required('Type is required'),
            frequencyType: Yup.string().required('Frequency is required'),
            email: Yup.string().email('Invalid email'),
            country: Yup.string().required('Country is required'),
            address1: Yup.string().max(255, 'Maximum of 255 characters allowed'),
            address2: Yup.string().max(255, 'Maximum of 255 characters allowed'),
            postalCode: Yup.string().max(255, 'Maximum of 255 characters allowed'),
            website: Yup.string().max(255, 'Maximum of 255 characters allowed'),

        });

    const initialValues: ISocialForm = {
        name: '',
        description: '',
        contactName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        phoneNumber: '',
        email: '',
        website: '',
        socialType: '',
        frequencyType: '',
        policy: false,
        policyDescription: '',
        active: true,
        latitude: 0.0,
        longitude: 0.0
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    function convertFormToSubmit(form: ISocialForm): ISocialSubmit {
        return { ...form,
            socialType: {'id': Number(form.socialType)},
            frequencyType: {'id': Number(form.frequencyType)},
            state: form.state === '' ? null : {'id': Number(form.state)},
            country: form.country === '' ? null : {'id': Number(form.country)} };
    }

    const handleSubmit = async (values: ISocialForm) => {
        // FORM CONVERSION
        const updatedFormValues = convertFormToSubmit(values);

        let url = id !== '0' ? `${apiRoutes.SOCIALS}/${id}` :`${apiRoutes.SOCIALS}`;
        let method = id !== '0' ? HttpMethods.PUT : HttpMethods.POST;

        axiosRequest(method, url, "Social Group", updatedFormValues).then(response => {
            navigate(`/socials/${response.data.id}`);
        }).catch(error => {
            handleRequestError(error, "Social Group")
        });
    }

    const fetchSocial = async () => {
        if (id !== '0') {
            axiosRequest(HttpMethods.GET, `${apiRoutes.SOCIALS}/${id}`, "Social Group").then(response => {
                // make sure response is null safe
                const data = replaceNullsWithEmptyString(response.data);
                formik.setValues({
                    ...data,
                    socialType: data.socialType?.id ?? '',
                    frequencyType: data.frequencyType?.id ?? '',
                    country: data.country?.id ?? '',
                    state: data.state?.id ?? '',
                });
            }).catch(error => {
                console.error(error);
            }).finally( () => {
                setIsLoading(false)
            })
        } else {
            setIsLoading(false);
        }
    }

    // SOCIAL FETCH SCRIPT
    useEffect(() => {
        fetchSocial();
        // eslint-disable-next-line
    }, [id]);

    // CHANGE SCRIPTS
    const countryChangedHandler = async (countryId: number) => {
        try {
            const response = await axios.get(`${apiRoutes.STATES}/${countryId}`);
            setStatesList(response.data.sort((a: IState, b: IState) => a.name.localeCompare(b.name)));
        } catch (error) {
            setStatesList([]);
            toast.error("Error getting State list");
            console.error(error);
        }
    };

    useEffect(() => {
        if (formik.values.country !== '') {
            countryChangedHandler(Number(formik.values.country));
        }
        // eslint-disable-next-line
    }, [formik.values.country]);

    //  PAGE RETURN
    if (isLoading) {
        return (
            <div>
                <HelmetEntityComponent title="Social Group Edit" entity="Social Group"/>
                <h1 className='mb-3'>{pageTitle}</h1>
                <SocialRulesHeader/>
                <h2>Page loading... </h2>
            </div>
        )
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Social Group Edit" entity="Social Group"/>
                <h1 className='mb-3'>{pageTitle}</h1>
                <SocialRulesHeader/>

                <Form noValidate onSubmit={formik.handleSubmit}>

                    <h4>Group Information</h4>
                    <Form.Group controlId="formName">
                        <Form.Label>Social Group Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('name')}
                            isInvalid={Boolean(formik.touched.name && formik.errors.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>Social Group Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('description')}
                            isInvalid={Boolean(formik.touched.description && formik.errors.description)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.description}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formSocialType">
                        <Form.Label>Meeting Type</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('socialType')}
                            isInvalid={Boolean(formik.touched.socialType && formik.errors.socialType)}
                        >
                            <option value="">Select a Type</option>
                            {socialTypes.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.socialType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formFrequencyType">
                        <Form.Label>Meeting Frequency</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('frequencyType')}
                            isInvalid={Boolean(formik.touched.frequencyType && formik.errors.frequencyType)}
                        >
                            <option value="">Select a Frequency</option>
                            {frequencyTypes.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.frequencyType}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formWebsite">
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('website')}
                            isInvalid={Boolean(formik.touched.website && formik.errors.website)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.website}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <h4>Contact Information</h4>
                    <Form.Group controlId="formContactName">
                        <Form.Label>Contact Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('contactName')}
                            isInvalid={Boolean(formik.touched.contactName && formik.errors.contactName)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.contactName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('phoneNumber')}
                            isInvalid={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.phoneNumber}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('email')}
                            isInvalid={Boolean(formik.touched.email && formik.errors.email)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPolicy">
                        <Form.Label>Fragrance Free Policy</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={formik.values.policy}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={Boolean(formik.touched.policy && formik.errors.policy)}
                            name='policy'
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.policy}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPolicyDescription">
                        <Form.Label>Fragrance Free Policy Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...formik.getFieldProps('policyDescription')}
                            isInvalid={Boolean(formik.touched.policyDescription && formik.errors.policyDescription)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.policyDescription}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br />
                    <h4>Meeting Location</h4>
                    <Form.Group controlId="formAddress1">
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('address1')}
                            isInvalid={Boolean(formik.touched.address1 && formik.errors.address1)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.address1}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formAddress1">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('address2')}
                            isInvalid={Boolean(formik.touched.address2 && formik.errors.address2)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.address2}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('city')}
                            isInvalid={Boolean(formik.touched.city && formik.errors.city)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.city}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCountryId">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('country')}
                            isInvalid={Boolean(formik.touched.country && formik.errors.country)}
                        >
                            <option value="">Select a Country</option>
                            {countryList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.country}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formStateId">
                        <Form.Label>State/Province</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('state')}
                            isInvalid={Boolean(formik.touched.state && formik.errors.state)}
                        >
                            <option value="">Select a State/Province</option>
                            {statesList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.state}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPostalCode">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('postalCode')}
                            isInvalid={Boolean(formik.touched.postalCode && formik.errors.postalCode)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.postalCode}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="active">
                        <Form.Label>Group Currently Active?</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={formik.values.active}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={Boolean(formik.touched.active && formik.errors.active)}
                            name='active'
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.active}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br/>
                    <Button type="submit">Submit</Button>
                    <Button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</Button>
                </Form>
            </div>
        );
    }
};

export default SocialEditForm;
