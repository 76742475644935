import React, {useEffect, useState} from 'react';
import {ISupport} from '../../interfaces/support.interface';
import apiRoutes from "../../config/apiRoutes";
import {useNavigate, useParams} from "react-router-dom";
import authUser from "../../services/auth.user";
import Support from "./components/Support";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";


const SupportPage: React.FC = () => {
    const [support, setSupport] = useState<ISupport>();
    const {id} = useParams();
    const authLevel = authUser(0)

    const handleEditSupport = () => {
        window.location.href = apiRoutes.BASE + `/support/${id}/edit`;
    };

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleDelete = async () => {
        axiosRequest(HttpMethods.DELETE, apiRoutes.SUPPORT + `/${id}`, "Support Ticket").then(response => {
            navigate('/support');
        }).catch(error => {
            handleRequestError(error, "Support Ticket");
        });
    }

    const fetchSupport = async () => {
        axiosRequest(HttpMethods.GET, apiRoutes.SUPPORT + `/${id}`, "Support Ticket").then(response => {
            setSupport({
                ...response.data
            });
        }).catch(error => {
            handleRequestError(error, "Support Ticket");
        });
    };

    useEffect(() => {
        fetchSupport();
    }, [id]);

    if (!support) {
        return (
            <div>
                <HelmetEntityComponent title="Support Ticket View" entity="Support Ticket"/>
                Loading support...
            </div>);
    } else if (authLevel === 3) {
        return (
            <div>
                <HelmetEntityComponent title="Support Ticket View" entity="Support Ticket"/>
                <Support support={support}/>
                <button className="btn btn-primary m-2" onClick={handleEditSupport}>Edit Ticket</button>
                <button className="btn btn-danger m-2" onClick={handleDelete}>Delete Ticket</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        );
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Support Ticket View" entity="Support Ticket"/>
                <Support support={support}/>
                <button className="btn btn-primary m-2" onClick={handleEditSupport}>Edit Support</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        );
    }
};

export default SupportPage;