export const replaceNullsWithEmptyString = <T>(data: T) => {
    let result = {} as T;

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            if (
                typeof data[key] === 'object' &&
                data[key] !== null &&
                !(data[key] instanceof Array)
            ) {
                result[key] = replaceNullsWithEmptyString(data[key]);
            } else if (data[key] === null) {

                // @ts-ignore
                result[key] = '' as unknown as T[keyof T];
            } else {
                result[key] = data[key];
            }
        }
    }
    return result;
}