import React from 'react';
import {ISocial} from '../../../interfaces/social.interface';
import Address from "../../utility/Address";

interface SocialProps {
    social: ISocial;
}

const SocialPage: React.FC<SocialProps> = ({social}) => {
    if (!social) {
        return <div>Loading Social Group...</div>;
    } else return (
        <div>
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">{social.name}</h3>
            <h4>Group Information</h4>
            <div className="ml-3">
                <p className='mb-2'><strong>Group Name: </strong>{social.name || ""}</p>
                <p className='mb-2'><strong>Meeting
                    Type: </strong> {social.socialType ? social.socialType.name || "" : ""}</p>
                <p className='mb-2'><strong>Meeting
                    Frequency: </strong> {social.frequencyType ? social.frequencyType.name || "" : ""}</p>
                <p className='mb-2'><strong>Description: </strong> {social.description || ""}</p>
                <p className='mb-2'><strong>Fragrance Free Policy:</strong> {social.policy ? "Yes" : "No"}</p>
                <p className='mb-2'><strong>Fragrance Free Policy
                    Description:</strong> {social.policyDescription || ""}</p>
                <p className='mb-2'><strong>Website: </strong>
                    <a href={social.website || "#"}
                       onClick={e => {
                           e.preventDefault();
                           if (social.website) window.open(social.website, "_blank")
                       }}>
                        {social.website || ""}
                    </a></p>

                <br />
                <h4>Contact Information</h4>
                <p className='mb-2'><strong>Contact Name: </strong> <a
                    href={social.contactName || ""}>{social.contactName || ""}</a></p>
                <p className='mb-2'><strong>Contact Phone Number: </strong> <a
                    href={social.phoneNumber || ""}>{social.phoneNumber || ""}</a></p>
                <p className='mb-2'><strong>Contact Email: </strong> <a
                    href={social.email || ""}>{social.email || ""}</a></p>

                <br />
                <h4>Meeting Location</h4>
                <Address
                    address1={social.address1}
                    address2={social.address2}
                    city={social.city}
                    state={social.state}
                    country={social.country}
                    postalCode={social.postalCode}
                    phoneNumber=''
                    email=''
                />

                <p className="mt-3 mb-0 text-muted font-italic">Social Group Id: {social.id || ""}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Created by
                    Id: {social.createdById ? social.createdById || "" : "None"} on {
                        social.createdDate ? new Date(social.createdDate).toLocaleDateString() : "None"}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Modified by
                    Id: {social.modifiedById ? social.modifiedById || "" : "None"} on {
                        social.modifiedDate ? new Date(social.modifiedDate).toLocaleDateString() : "None"}</p>
            </div>

        </div>
    );

};

export default SocialPage;