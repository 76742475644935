import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import apiRoutes from "../../config/apiRoutes";
import {IState, useTypesContext} from "../../contexts/typeContexts";
import {IUserForm, IUserSubmit} from "../../interfaces/user.interface";
import {toast} from 'react-toastify';
import Verify from "../utility/Verify";
import * as Yup from "yup";
import {useFormik} from "formik";
import {replaceNullsWithEmptyString} from "../utility/replaceNullsWithEmptyString";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {HttpMethods} from "../../routes/HttpMethods";
import {axiosRequest, handleRequestError} from "../../services/axios.service";

const UserEdit = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {countryList} = useTypesContext();
    const [statesList, setStatesList] = useState<IState[]>([]);
    const handleGoBack = () => {navigate(-1);};

    const ValidationSchema = () =>
        Yup.object().shape({
            email: Yup.string().email('Invalid email'),
        });

    const initialValues: IUserForm = {
        id: 0,
        username: '',
        email: '',
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        phoneNumber: '',
        website: '',
        latitude: 0.0,
        longitude: 0.0
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    // SUBMISSION SCRIPTS
    function convertFormToSubmit(form: IUserForm): IUserSubmit {
        return {
            ...form,
            state: form.state === "" ? null : {'id': Number(form.state)},
            country: form.country === "" ? null : {'id': Number(form.country)}
        }
    }

    const fetchUser = async () => {
        if (id !== '0') {
            axiosRequest(HttpMethods.GET, apiRoutes.USER, "User").then(response => {
                // make sure response is null safe
                const data = replaceNullsWithEmptyString(response.data);
                // set values
                formik.setValues({
                    ...data,
                    'country': data.country?.id ?? '',
                    'state': data.state?.id ?? ''
                });
            }).catch(error => {
                handleRequestError(error, "User");
            });
        }
    }


    // USER FETCH SCRIPT
    useEffect(() => {
        fetchUser();
    }, [id]);


    // CHANGE SCRIPTS
    const countryChangedHandler = async (countryId: number) => {
        try {
            const response = await axios.get(`${apiRoutes.STATES}/${countryId}`);
            setStatesList(response.data.sort((a: IState, b: IState) => a.name.localeCompare(b.name)));
        } catch (error) {
            setStatesList([]);
            toast.error("Error getting State list");
            console.error(error);
        }
    };

    useEffect(() => {
        if (formik.values.country !== '') {
            countryChangedHandler(Number(formik.values.country));
        }
    }, [formik.values.country]);

    const handleSubmit = async (values: IUserForm) => {
        // FORM CONVERSION
        const updatedFormValues = convertFormToSubmit(values);

        axiosRequest(HttpMethods.PUT, `${apiRoutes.USER}`, "User", updatedFormValues).then(response => {
            navigate(`/profile`);
        }).catch(error => {
            handleRequestError(error, "User");
        });
    }

    // CLEAR USER DATA
    const clearUser = async () => {
        formik.resetForm();
        axiosRequest(HttpMethods.PUT, `${apiRoutes.USER}`, "User", formik.values).then(response => {
            navigate(`/profile`);
        }).catch(error => {
            handleRequestError(error, "User");
        });
    };

    // PAGE RETURN
        return (
            <div>
                <HelmetEntityComponent title="User Edit" entity="User"/>
                <h1 className='mb-3'>Edit User</h1>
                <h4 className='mb-3'>Unchangable Values:</h4>
                <p>User Id: {formik.values.id || ""}</p>
                <p>Username: {formik.values.username || ""}</p>
                <p>Email: {formik.values.email || ""}</p>
                <br />
                <h4 className='mb-3'>Changable Values:</h4>

                <Form noValidate onSubmit={formik.handleSubmit}>

                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('name')}
                            isInvalid={Boolean(formik.touched.name && formik.errors.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formAddress1">
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('address1')}
                            isInvalid={Boolean(formik.touched.address1 && formik.errors.address1)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.address1}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formAddress1">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('address2')}
                            isInvalid={Boolean(formik.touched.address2 && formik.errors.address2)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.address2}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('city')}
                            isInvalid={Boolean(formik.touched.city && formik.errors.city)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.city}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCountryId">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('country')}
                            isInvalid={Boolean(formik.touched.country && formik.errors.country)}
                        >
                            <option value="">Select a Country</option>
                            {countryList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.country}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formStateId">
                        <Form.Label>State/Province</Form.Label>
                        <Form.Control
                            as="select"
                            {...formik.getFieldProps('state')}
                            isInvalid={Boolean(formik.touched.state && formik.errors.state)}
                        >
                            <option value="">Select a State/Province</option>
                            {statesList.map((option, index) => (
                                <option key={index} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.state}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPostalCode">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('postalCode')}
                            isInvalid={Boolean(formik.touched.postalCode && formik.errors.postalCode)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.postalCode}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('phoneNumber')}
                            isInvalid={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.phoneNumber}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formWebsite">
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('website')}
                            isInvalid={Boolean(formik.touched.website && formik.errors.website)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.website}
                        </Form.Control.Feedback>
                    </Form.Group>


                    <Button type="submit">Submit</Button>
                    <Button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</Button>
                    <Verify onConfirm={clearUser}>
                        <Button className="btn btn-danger m-2">Clear User Data</Button>
                    </Verify>
                </Form>
            </div>
        );
};

export default UserEdit;
