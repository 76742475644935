import React from "react";

import {IForgotUsername} from "../../interfaces/user.interface";
import {NavigateFunction, useNavigate} from 'react-router-dom';
import apiRoutes from "../../config/apiRoutes";
import {Button, Form} from "react-bootstrap";
import {toast} from 'react-toastify';
import * as Yup from "yup";
import {useFormik} from "formik";
import HelmetComponent from "../utility/HelmetComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";

const ForgotUsername: React.FC = () => {

    const navigate: NavigateFunction = useNavigate();

    const ValidationSchema = () =>
        Yup.object().shape({
            email: Yup.string().required("Email address is required").email("Invalid email address"),
        });

    const initialValues: IForgotUsername = {
        email: '',
    };

    const formik= useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    const handleSubmit = async (values: IForgotUsername) => {
        axiosRequest(HttpMethods.POST, `${apiRoutes.FORGOT_USERNAME}`, "Forgotten Username").then(response => {
            toast.success("Username was sent to the email on file");
            navigate("/login")
        }).catch(error => {
            handleRequestError(error, "Forgotten Username");
        });
    }

    return (
        <div className="col-md-12">
            <HelmetComponent
                title="Forgot Username"
            />
            <div className="card card-container ">
                <p style={{textAlign: 'center', width: '100%', fontSize: '2rem'}}>Forgotten Username</p>
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />
                <Form noValidate onSubmit={formik.handleSubmit}>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="text"
                            {...formik.getFieldProps('email')}
                            isInvalid={Boolean(formik.touched.email && formik.errors.email)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <br/>
                    <Button type="submit">Submit</Button>
                </Form>

                <p className="mt-4" style={{textAlign: 'center', justifyContent: 'center'}}>
                    If the email address exists, an email will be sent with the Username.
                    <br />
                    <br />
                    For additional assistance email
                    <br />
                    <a href="mailto:fragrancefreeresources@gmail.com">fragrancefreeresources@gmail.com</a>.
                </p>

            </div>
        </div>
    );
};

export default ForgotUsername;