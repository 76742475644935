import React, {useEffect, useState} from 'react';
import {IUser} from '../../interfaces/user.interface';
import apiRoutes from "../../config/apiRoutes";
import {useNavigate, useParams} from "react-router-dom";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";


const User: React.FC = () => {
    const [user, setUser] = useState<IUser>();
    const {id} = useParams();

    const handleEditUser = () => {
        window.location.href = apiRoutes.BASE + `/user/edit`;
    };

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const fetchUser = async () => {
        axiosRequest(HttpMethods.GET, apiRoutes.USERS + '/' + String(id) , "Users").then(response => {
            setUser(response.data);
        }).catch(error => {
            handleRequestError(error, "Users");
        });
    }

    useEffect(() => {
        fetchUser();
    }, [id]);

    if (!user) {
        return (
            <div>
                <HelmetEntityComponent title="User View" entity="User"/>
                Loading user...
            </div>);
    } else return (
        <div>
            <HelmetEntityComponent title="User View" entity="User"/>
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">User Profile</h3>
            <div className="ml-3">
                <p className='mb-2'><strong>User Id:</strong> {user.id || ""}</p>
                <p className='mb-2'><strong>Username:</strong> {user.username || ""}</p>
                <br />
                <p className='mb-2'><strong>Name:</strong> {user.name || ""}</p>
                <p className='mb-2'>
                    <strong>Address:</strong> {user.address1 || ""} {user.address2 ? user.address2 || "" : ""}
                </p>
                <p className='mb-2'><strong>City:</strong> {user.city || ""}</p>
                <p className='mb-2'>
                    <strong>State/Province/Region:</strong> {user.state ? user.state.name || "" : ""}</p>
                <p className='mb-2'><strong>Postal Code:</strong> {user.postalCode || ""}</p>
                <p className='mb-2'><strong>Country:</strong> {user.country ? user.country.name || "" : ""}</p>
                <br />
                <p className='mb-2'><strong>Phone Number:</strong> {user.phoneNumber || ""}</p>
                <p className='mb-2'><strong>Email:</strong> <a
                    href={"mailto:" + (user.email || "")}>{user.email || ""}</a></p>
                <p className='mb-2'><strong>Website:</strong> <a
                    href={user.website || ""}>{user.website || ""}</a></p>

                <p className="mt-3 mb-0 text-muted font-italic">User Id: {user.id || ""}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Created by
                    Created on {user.createdDate ? new Date(user.createdDate).toLocaleDateString() : "None"}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Modified by
                    Updated on {user.modifiedDate ? new Date(user.modifiedDate).toLocaleDateString() : "None"}</p>

            </div>
            <button className="btn btn-primary m-2" onClick={handleEditUser}>Edit User</button>
            <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
        </div>
    );

};

export default User;