import { Table} from "react-bootstrap";
import StarRating from "../../utility/StarRating";
import React from "react";
import {IProduct} from "../../../interfaces/products.interface";

interface IProps {
    content: IProduct[];
}

const ProductsTable: React.FC<IProps> = ({ content }) => {
    if (content.length === 0) {
        return (
            <>
                <br />
                <h4>No Products found... </h4>
                <br />
            </>
        )
    } else {
        return (
            <>
                <Table size="md" striped bordered hover responsive >
                    <thead className="thead-light">
                    <tr>
                        <th>Name</th>
                        <th>Company</th>
                        <th>FF Policy</th>
                        <th>Category</th>
                        <th>Type</th>
                        <th>Reviews/Stars</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() => window.location.href = `/products/${item.id}`}
                            style={{cursor: "pointer"}}
                        >
                            <td>{item.name?.length > 65 ? item.name.substring(0, 62) + "..." : item.name || ""}</td>
                            <td>{item.companyEntity.name?.length > 25 ? item.companyEntity.name.substring(0, 25) + "..." : item.companyEntity.name || ""}</td>
                            <td>{item.companyEntity.policy ? "Yes" : "No"}</td>
                            <td>{item.productType.productCategoryType.name ? item.productType.productCategoryType.name || "" : ""}</td>
                            <td>{item.productType ? item.productType.name || "" : ""}</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    {item.reviews ? `${item.reviews} / ` : ""}
                                    <StarRating rating={item.rating || 0}/>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        )
    }
}

export default ProductsTable;