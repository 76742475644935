import {Table} from "react-bootstrap";
import StarRating from "../../utility/StarRating";
import React from "react";
import {IHome} from "../../../interfaces/homes.interface";

interface IProps {
    content: IHome[];
}

const HomesTable: React.FC<IProps> = ({ content }) => {
    if (content.length === 0) {
        return (
            <>
                <br />
                <h4>No Rental Homes found...</h4>
                <br />
            </>
        )
    } else {
        return (
            <>
                <Table size="md" striped bordered hover responsive >
                    <thead className="thead-light">
                    <tr>
                        <th>Home Name</th>
                        <th>Type</th>
                        <th>People</th>
                        <th>Pets</th>
                        <th>FF Policy</th>
                        <th>State/Province</th>
                        <th>Country</th>
                        <th>Reviews/Stars</th>
                    </tr>
                    </thead>
                    <tbody>
                    {content.map((item) => (
                        <tr
                            key={item.id}
                            onClick={() => window.location.href = `/homes/${item.id}`}
                            style={{cursor: "pointer"}}
                        >
                            <td>{item.name ? item.name || "" : ""}</td>
                            <td>{item.homeType ? item.homeType.name || "" : ""}</td>
                            <td>{item.people || ""}</td>
                            <td>{item.petsAllowed ? "Yes" : "No"}</td>
                            <td>{item.policy ? "Yes" : "No"}</td>
                            <td>{item.state ? item.state.name || "" : ""}</td>
                            <td>{item.country ? item.country.name || "" : ""}</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    {item.reviews ? `${item.reviews} / ` : ""}
                                    <StarRating rating={item.rating || 0}/>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        )
    }
}

export default HomesTable;