import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import HelmetComponent from "../utility/HelmetComponent";
import {useAuth} from "../../contexts/authContext";

const Logout: React.FC = () => {
    let navigate = useNavigate();
    const { setCurrentUser, setShowModeratorPage, setShowAdminPage, setAuthMessage } = useAuth();


    useEffect(() => {
        const logoutFunction = async () => {
            setShowModeratorPage(false);
            setShowAdminPage(false);
            setCurrentUser(undefined);
            setAuthMessage("You have been logged out.");
            localStorage.removeItem("user");
            localStorage.removeItem("typesData");
            setAuthMessage("You have been logged out. Please login again.");
            navigate("/")
        };
        logoutFunction();
    });

    return (
        <div>
            <HelmetComponent
                title="Logout"
            />
            <p>Logging out...</p>
        </div>
    );
};

export default Logout;