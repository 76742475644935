import React from 'react';
import {IProduct} from '../../../interfaces/products.interface';
import Address from "../../utility/Address";
import ProductReviewList from "../../reviews/components/ProductReviewList";

interface ProductProps {
    product: IProduct;
}

const Product: React.FC<ProductProps> = ({product}) => {


    if (!product) {
        return <div>Loading product...</div>;
    } else return (
        <div>
            <h3 className="p-3 mb-4 bg-secondary bg-gradient text-white rounded-3">{product.companyEntity.name}: {product.name}</h3>
            <h4>Product Information</h4>
            <div className="ml-3">
                <p className='mb-2'><strong>Product Name: </strong>{product.name || ""}</p>
                <p className='mb-2'><strong>Product
                    Category:</strong> {product.productType ? product.productType.productCategoryType.name || "" : ""}
                </p>
                <p className='mb-2'><strong>Product
                    Type:</strong> {product.productType ? product.productType.name || "" : ""}</p>
                <p className='mb-2'><strong>Description:</strong> {product.description || ""}</p>
                <p className="mt-3 mb-0 font-weight-bold text-success font-italic">Please click the link below to view
                    Product information and photographs</p>
                <p className='mb-2'><strong>Website: </strong>
                    <a href={product.manufacturerLink || "#"}
                       onClick={e => {
                           e.preventDefault();
                           if (product.manufacturerLink) window.open(product.manufacturerLink, "_blank")
                       }}>
                        {product.manufacturerLink || ""}
                    </a></p>


                <br />
                <h4 className='mb-2'>Company Information</h4>
                <p className='mb-2'><strong>Company Name:</strong> {product.companyEntity.name || ""}</p>
                <p className='mb-2'><strong>Company Fragrance Free
                    Policy:</strong> {product.companyEntity.policy ? "Yes" : "No"}</p>
                <p className='mb-2'><strong>Company Fragrance Free Policy
                    Description:</strong> {product.companyEntity.policyDescription || ""}</p>
                <Address
                    address1={product.companyEntity.address1}
                    address2={product.companyEntity.address2}
                    city={product.companyEntity.city}
                    state={product.companyEntity.state}
                    country={product.companyEntity.country}
                    postalCode={product.companyEntity.postalCode}
                    phoneNumber={product.companyEntity.phoneNumber}
                    email={product.companyEntity.email}
                />
                <p className='mb-2'><strong>Company Website: </strong>
                    <a href={product.companyEntity.website || "#"}
                       onClick={e => {
                           e.preventDefault();
                           if (product.companyEntity.website) window.open(product.companyEntity.website, "_blank")
                       }}>
                        {product.companyEntity.website || ""}
                    </a></p>
                <br />
                <h4 className='mb-2'>Product Reviews</h4>
                <p className='mb-2'><strong>Number of Reviews: </strong>{product.reviews} </p>
                <p className='mb-2'><strong>Rating (out of 5): </strong>{product.rating.toFixed(2)} </p>

                <ProductReviewList productId={Number(product.id)}/>

                <p className="mt-3 mb-0 text-muted font-italic">Product Id: {product.id || ""}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Created by
                    Id: {product.createdById ? product.createdById || "" : "None"} on {
                        product.createdDate ? new Date(product.createdDate).toLocaleDateString() : "None"}</p>
                <p className="mt-0 mb-0 text-muted font-italic">Modified by
                    Id: {product.modifiedById ? product.modifiedById || "" : "None"} on {
                        product.modifiedDate ? new Date(product.modifiedDate).toLocaleDateString() : "None"}</p>
            </div>

        </div>
    );

};

export default Product;