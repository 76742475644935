import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {IProduct} from '../../../interfaces/products.interface';
import {ResponseData} from '../../../interfaces/pageable.interface';
import apiRoutes from "../../../config/apiRoutes";
import {Button, Col, Form, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import {ISearchBar} from "../../../interfaces/searchBar.interface";
import {useFormik} from "formik";
import {IProductType, useTypesContext} from "../../../contexts/typeContexts";
import PaginationComponent from "../../utility/renderPagination";
import ProductsTable from "./ProductsTable";
import * as Yup from "yup";
import {ICompany} from "../../../interfaces/companies.interface";
import {axiosRequest, handleRequestError} from "../../../services/axios.service";
import {HttpMethods} from "../../../routes/HttpMethods";
import {useSearchParams} from "react-router-dom";

const ProductsList: React.FC = () => {
    const [data, setData] = useState<ResponseData<IProduct>>();
    const {productTypes, productCategories} = useTypesContext();
    const [companyList, setCompanyList] = React.useState<ICompany[]>([])
    const [isLoading, setIsLoading] = React.useState(true);
    const [typesList, setTypeList] = React.useState<IProductType[]>([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(0);

    const initialValues: ISearchBar = {
        type: '',
        category: '',
        country: '',
        searchText: '',
        state: '',
        company: '',
    };

    const ValidationSchema = () =>
        Yup.object().shape({
            searchText: Yup.string(),
            category: Yup.number().typeError("Value must be a number"),
            country: Yup.number().typeError("Value must be a number"),
            type: Yup.number().typeError("Value must be a number"),
            state: Yup.number().typeError("Value must be a number")
        });

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            setPageNumber(0);
            fetchProducts();
        }
    });

    const setUrlParams = () => {
        const items = new URLSearchParams();
        /*        if (formik.values.searchText) items.set("searchText", formik.values.searchText);
                if (formik.values.category) items.set("category", formik.values.category);
                if (formik.values.country) items.set("country", formik.values.country);
                if (formik.values.state) items.set("state", formik.values.state);
                if (formik.values.type) items.set("type", formik.values.type);*/
        if (pageNumber !== 0) items.set("page", String(pageNumber + 1));
        setSearchParams(items);
    };

    const fetchProducts = async () => {
        setIsLoading(true);
        const params = {
            ...Object.fromEntries(
                Object.entries(formik.values).filter(([_, value]) => value !== '')
            ),
            ...(pageNumber !== 0 ? { page: pageNumber } : {}), // Only add page if pageNumber is not 0
        };
        axiosRequest(HttpMethods.GET, apiRoutes.PRODUCTS, "Products", null, {params}).then(response => {
            setData(response.data);
            setUrlParams();
            setIsLoading(false);
        }).catch(error => {
            handleRequestError(error, "Products");
        }).finally(() =>
            setIsLoading(false)
        );
    }

    // HOMES FETCH SCRIPT
    useEffect(() => {
        fetchProducts();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchProducts();
        // eslint-disable-next-line
    }, [pageNumber]);

    const handlePageChange = (page: number) => {
        setPageNumber(page);
    }

    useEffect(() => {
        updateTypes();
        // eslint-disable-next-line
    }, [formik.values.category]);

    const updateTypes = () => {
        setTypeList(productTypes.filter(typesList =>
            typesList.productCategoryType.id === Number(formik.values.category)));
    }

    // FETCH COMPANY LIST ON FIRST RENDER
    useEffect(() => {
        const fetchCompanies = async () => {
            axios.get(apiRoutes.COMPANIES)
                .then(response => {
                    if (response.data.length > 1) {
                        setCompanyList(response.data);
                    } else {
                        setCompanyList(response.data);
                    }
                })
                .catch(error => {
                    toast.error("Error getting Companies list")
                    setCompanyList([]);
                    console.error(error);
                });
        }
        fetchCompanies();
    }, []);

    if (isLoading) {
        return (
        <>
            <br />
            <h4>Products Loading... </h4>
            <br />
        </>
        )
    } else {
        return (
            <div>
                <div className="p-3 mb-2">
                    <h3>Fragrance Free Products</h3>

                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Row>
                            <Form.Group as={Col} xs={12} md={3} controlId="formCompany">
                                <Form.Label>Search by Company</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...formik.getFieldProps('company')}
                                    isInvalid={Boolean(formik.touched.category && formik.errors.company)}
                                >
                                    <option value="">Search by Company</option>
                                    {companyList.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.company}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={3} controlId="formCategory">
                                <Form.Label>Search by Category</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...formik.getFieldProps('category')}
                                    isInvalid={Boolean(formik.touched.category && formik.errors.category)}
                                >
                                    <option value="">Search by Category</option>
                                    {productCategories.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.category}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={3} controlId="formType">
                                <Form.Label>Search by Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...formik.getFieldProps('type')}
                                    isInvalid={Boolean(formik.touched.type && formik.errors.type)}
                                >
                                    <option value="">Select Category First</option>
                                    {typesList.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.type}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={3} controlId="formSearchText">
                                <Form.Label>Search by Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by Name"
                                    {...formik.getFieldProps('searchText')}
                                    isInvalid={Boolean(formik.touched.searchText && formik.errors.searchText)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.searchText}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={3} controlId="formSubmitButton">
                                <Button type="submit" className="mt-3 me-2" variant="primary">Search</Button>
                                <Button type="submit" className="mt-3" onClick={() => formik.resetForm()}
                                        variant="success">Reset</Button>
                            </Form.Group>
                        </Row>

                    </Form>
                </div>
                <ProductsTable content={data?.content || []}/>
                <PaginationComponent data={data} handlePageChange={handlePageChange}/>
            </div>
        );
    }
};

export default ProductsList;
