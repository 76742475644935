import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import Home from './components/Home';
import apiRoutes from "../../config/apiRoutes";
import {IHomeReviewForm, IHomeReviewSubmit} from "../../interfaces/home.review.interface";
import ReviewFormModal from "../reviews/components/ReviewFormModal";
import authUser from "../../services/auth.user";
import {IHome} from "../../interfaces/homes.interface";
import {toast} from 'react-toastify';
import Verify from "../utility/Verify";
import {Button} from "react-bootstrap";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";

const HomePage: React.FC = () => {
    const {id} = useParams();

    const [home, setHome] = useState<IHome>();
    const authLevel = authUser(home?.createdById || 0);
    const [showModal, setShowModal] = useState(false);
    const [reviewForm, setReviewForm] = useState<IHomeReviewForm>({
        name: '',
        comment: '',
        rating: 5,
        homeEntity: Number(id)
    });
    const [formErrors, setFormErrors] = useState<{ name?: string; rating?: string }>({});

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const validateForm = () => {
        const errors: { name?: string; rating?: string } = {};
        if (!reviewForm.name) {
            errors.name = 'Name is required';
        }
        if (reviewForm.rating < 1 || reviewForm.rating > 5) {
            errors.rating = 'Rating must be between 1 and 5';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    useEffect(() => {
        const fetchHome = async () => {
            try {
                const response = await axios.get(apiRoutes.HOMES + `/${id}`);
                if (!response.data) {
                    toast.error("Failed to get Homes.")
                    return
                }
                setHome(response.data);
            } catch (error) {
                console.error('Error fetching home data:', error);
            }
        };

        fetchHome();
    }, [id]);

    function convertFormToSubmit(form: IHomeReviewForm): IHomeReviewSubmit {
        return  {
            ...form,
            homeEntity: id !== undefined ? {'id': Number(id)} : null }
    }

    const handleAddReview = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        const updatedFormValues = convertFormToSubmit(reviewForm)
        axiosRequest(HttpMethods.POST, apiRoutes.HOMES + '/' + String(id) + '/reviews', "Home Review", updatedFormValues).then(response => {
            handleCloseModal();
            window.location.reload();
        }).catch(error => {
            handleCloseModal();
            handleRequestError(error, "Home Review");

        });
    };

    const handleEditHome = () => {
        window.location.href = apiRoutes.BASE + `/homes/${id}/edit`;
    };

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleDelete = async () => {
        axiosRequest(HttpMethods.DELETE, apiRoutes.HOMES + `/${id}`, "Users").then(response => {
            navigate('/homes');
        }).catch(error => {
            handleRequestError(error, "Rental Home");
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        setReviewForm({...reviewForm, [name]: value});
    };
    if (!home) {
        <HelmetEntityComponent title="Rental Home View" entity="Rental Home"/>
        return <div>Loading home...</div>;
    } else if (authLevel === 1) {
        return (
            <div>
                <HelmetEntityComponent title="Rental Home View" entity="Rental Home"/>
                <Home home={home}/>

                <ReviewFormModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    handleAddReview={handleAddReview}
                    reviewForm={reviewForm}
                    handleChange={handleChange}
                    formErrors={formErrors}
                />

                <button className="btn btn-primary m-2" onClick={handleEditHome}>Edit this Home</button>
                <button className="btn btn-success m-2" onClick={handleShowModal}>Add a Review</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
            </div>
        );
    } else if (authLevel >= 2) {
        return (
            <div>
                <HelmetEntityComponent title="Rental Home View" entity="Rental Home"/>
                <Home home={home}/>

                <ReviewFormModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    handleAddReview={handleAddReview}
                    reviewForm={reviewForm}
                    handleChange={handleChange}
                    formErrors={formErrors}
                />
                <div style={{display: 'flex', alignItems: 'left', justifyContent: 'flex-start'}}>
                <button className="btn btn-primary m-2" onClick={handleEditHome}>Edit this Home</button>
                <button className="btn btn-success m-2" onClick={handleShowModal}>Add a Review</button>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
                <Verify onConfirm={handleDelete} confirmText={"Delete Rental Home"}
                        messageText={"Are you sure you want to proceed? This action cannot be undone.\n" +
                            "Deleting this rental home will also delete all reviews about the rental home."}>
                    <Button className="btn btn-danger m-2">Delete this Rental Home</Button>
                </Verify>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Rental Home View" entity="Rental Home"/>
                <Home home={home}/>
                <button className="btn btn-secondary m-2" onClick={handleGoBack}>Go Back</button>
                <p>Please login to add or edit Rental Homes and Reviews.</p></div>
        );
    }
};

export default HomePage;
