import React, { createContext, useState, useContext, ReactNode } from 'react';
import {IUserAuth} from "../interfaces/user.interface";

type AuthContextType = {
    showModeratorPage: boolean;
    setShowModeratorPage: React.Dispatch<React.SetStateAction<boolean>>;
    showAdminPage: boolean;
    setShowAdminPage: React.Dispatch<React.SetStateAction<boolean>>;
    currentUser: IUserAuth | undefined;
    setCurrentUser: (user: IUserAuth | undefined) => void;
    authMessage: string;
    setAuthMessage: React.Dispatch<React.SetStateAction<string>>;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
    children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [showModeratorPage, setShowModeratorPage] = useState<boolean>(false);
    const [showAdminPage, setShowAdminPage] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<IUserAuth | undefined>(undefined);
    const [authMessage, setAuthMessage] = useState<string>("");

    return (
        <AuthContext.Provider
            value={{ showModeratorPage, setShowModeratorPage, showAdminPage, setShowAdminPage, currentUser, setCurrentUser, authMessage, setAuthMessage }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
}