import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {toast} from 'react-toastify';
import {HttpMethods} from '../routes/HttpMethods';
import apiRoutes from '../config/apiRoutes';

// Axios interceptors for token refresh on 401
axios.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
        if (error.response?.status === 401) {
            try {
                await axios.post(apiRoutes.AUTH + '/refresh', {}, { withCredentials: true });
                return axios(error.config!); // Retry original request
            } catch {
                window.location.href = '/logout';
            }
        }
        return Promise.reject(error);
    }
);

// Core axios request function
async function axiosRequest<T = any>(
    method: HttpMethods,
    url: string,
    entity: string,
    data?: T,
    config?: AxiosRequestConfig
): Promise<AxiosResponse> {
    try {
        const response = await axios({
            method,
            url,
            data,
            withCredentials: true,
            headers: {'Content-Type': 'application/json'},
            ...config,
        });

        // Handle success message based on status code and method
        if (method !== HttpMethods.GET){
            const successMessage = getSuccessMessage(method, response.status, entity);
            toast.success(successMessage);
        }


        return response;
    } catch (error) {
        return Promise.reject(error);
    }
}

// Centralized success messages
const getSuccessMessage = (method: HttpMethods, status: number, entity: string): string => {
    switch (status) {
        case 200:
            return entity === "Login" ? `${entity} successful.` : `${entity} successfully updated.`;
        case 201:
            return `${entity} created successfully.`;
        case 204:
            return `${entity} deleted successfully.`;
        default:
            return 'Success.';
    }
};

// Error handler function to be used in the UI components or external logic
const handleRequestError = (error: AxiosError, entity: string) => {
    if (error.response) {
        const errorMessage = getErrorMessage(error.response.status, entity);
        toast.error(errorMessage);
    } else if (error.request) {
        toast.error('Error: Request was made but no response received.');
    } else {
        toast.error(`Error: ${error.message}`);
    }
};

// Centralized error messages
const getErrorMessage = (status: number, entity: string): string => {
    switch (status) {
        case 400: return `Bad request. ${entity} may already exist.`;
        case 401: return 'Unauthorized. Please log in again.';
        case 404: return `${entity} not found.`;
        case 500: return `Server error while processing ${entity}. Try again later.`;
        default: return 'Unknown error occurred.';
    }
};

export { axiosRequest, handleRequestError };