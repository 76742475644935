import React, {useEffect, useState} from 'react';
import {ISupport} from '../../interfaces/support.interface';
import apiRoutes from "../../config/apiRoutes";
import {Button, Col, Form, Row} from "react-bootstrap";
import {ResponseData} from "../../interfaces/pageable.interface";
import {ISupportBar} from "../../interfaces/searchBar.interface";
import {useFormik} from "formik";
import {useTypesContext} from "../../contexts/typeContexts";
import PaginationComponent from "../utility/renderPagination";
import * as Yup from "yup";
import SupportTable from "./components/SupportTable";
import HelmetEntityComponent from "../utility/HelmetEntityComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";
import {useSearchParams} from "react-router-dom";

const SupportList: React.FC = () => {
    const [data, setData] = useState<ResponseData<ISupport>>();
    const {supportTypes, entityTypes} = useTypesContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const initialValues: ISupportBar = {
        entityType: '',
        supportType: '',
        active: true,
        searchText: ''
    };

    const ValidationSchema = () =>
        Yup.object().shape({
            entityType: Yup.number().typeError("Value must be a number"),
            supportType: Yup.number().typeError("Value must be a number"),
        });

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            setPageNumber(0);
            fetchSupport();
        }
    });

    const setUrlParams = () => {
        const items = new URLSearchParams();
        /*        if (formik.values.searchText) items.set("searchText", formik.values.searchText);
                if (formik.values.category) items.set("category", formik.values.category);
                if (formik.values.country) items.set("country", formik.values.country);
                if (formik.values.state) items.set("state", formik.values.state);
                if (formik.values.type) items.set("type", formik.values.type);*/
        if (pageNumber !== 0) items.set("page", String(pageNumber + 1));
        setSearchParams(items);
    };

    const fetchSupport = async () => {
        setIsLoading(true);
        const params = {
            ...Object.fromEntries(
                Object.entries(formik.values).filter(([_, value]) => value !== '')
            ),
            ...(pageNumber !== 0 ? { page: pageNumber } : {}), // Only add page if pageNumber is not 0
        };
        axiosRequest(HttpMethods.GET, apiRoutes.SUPPORT, "Support Tickets", null, {params}).then(response => {
            setData(response.data);
            setUrlParams();
            setIsLoading(false);
        }).catch(error => {
            handleRequestError(error, "Support Tickets");
        }).finally(() =>
            setIsLoading(false)
        );
    }

    // Support FETCH SCRIPT
    useEffect(() => {
        fetchSupport();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchSupport();
        // eslint-disable-next-line
    }, [pageNumber]);

    const handlePageChange = (page: number) => {
        setPageNumber(page);
    }

    if (isLoading) {
        return (
            <>
                <br />
                <h4>Support Tickets Loading... </h4>
                <br />
            </>
        )
    } else {
        return (
            <div>
                <HelmetEntityComponent title="Support Tickets List" entity="Support Ticket"/>
                <div className="p-3 bg-secondary bg-gradient text-white rounded-3">
                    <h3>Support Tickets List</h3>

                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Row>
                            <Form.Group as={Col} xs={12} md={4} controlId="formSupportType">
                                <Form.Label>Search by Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...formik.getFieldProps('supportType')}
                                    isInvalid={Boolean(formik.touched.supportType && formik.errors.supportType)}
                                >
                                    <option value="">Search by Type</option>
                                    {supportTypes.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.supportType}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={4} controlId="formEntity">
                                <Form.Label>Search by Entity</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...formik.getFieldProps('entityType')}
                                    isInvalid={Boolean(formik.touched.entityType && formik.errors.entityType)}
                                >
                                    <option value="">Search by Entity Type</option>
                                    {entityTypes.map((option, index) => (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.entityType}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={4} controlId="formSearchText">
                                <Form.Label>Search by Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by Name"
                                    {...formik.getFieldProps('searchText')}
                                    isInvalid={Boolean(formik.touched.searchText && formik.errors.searchText)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.searchText}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formActive">
                                <Form.Label>Active</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="active-switch"
                                    checked={formik.values.active}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={Boolean(formik.touched.active && formik.errors.active)}
                                    name='active'
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.active}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={4} controlId="formSubmitButton">
                                <Button type="submit" className="m-2" variant="primary">Search</Button>
                                <Button type="submit" onClick={() => formik.resetForm()}
                                        variant="success">Reset</Button>
                            </Form.Group>

                        </Row>
                    </Form>
                </div>

                <SupportTable content={data?.content || []}/>
                <PaginationComponent data={data} handlePageChange={handlePageChange}/>
            </div>
        );
    }
};

export default SupportList;
