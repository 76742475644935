import React from 'react';


const ProductesRulesHeader: React.FC = () => {


    return (
        <div className='mb-4'>
            <h4>Product Rules</h4>
            <p>By adding a product, you agree to the following rules:</p>
            <ul>
                <li>You cannot add any product or links to which you or someone you know would make a profit.</li>
                <li>Information submitted must be accurate and appropriate in nature. Submitting false or inappropriate
                    content will result in your account being locked.
                </li>
                <li>Products must be fragrance-free or items that aid in fragrance-free living.</li>
                <li>Any user may edit a products details for accuracy or completeness.</li>
                <li>Only users that created the product or a moderator can delete a product. Please submit a support
                    ticket to delete a product if needed.
                </li>
                <li>Please login and create a support ticket to add Types, Categories, Countries, or States that do not
                    exist.
                </li>
            </ul>
        </div>
    );

};

export default ProductesRulesHeader;