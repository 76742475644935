import React from "react";
import {IUserLoginSubmit} from "../../interfaces/user.interface";
import apiRoutes from "../../config/apiRoutes";
import {Button, Form} from "react-bootstrap";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Link, NavigateFunction, useNavigate} from "react-router-dom";
import HelmetComponent from "../utility/HelmetComponent";
import {axiosRequest, handleRequestError} from "../../services/axios.service";
import {HttpMethods} from "../../routes/HttpMethods";
import {useAuth} from "../../contexts/authContext";

const Login: React.FC = () => {
    const navigate: NavigateFunction = useNavigate();
    const { setCurrentUser, setShowModeratorPage, setShowAdminPage, setAuthMessage } = useAuth();



    const ValidationSchema = () =>
        Yup.object().shape({
            username: Yup.string().required("Username is required"),
            password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
        });

    const initialValues: IUserLoginSubmit = {
        username: '',
        password: '',
    };

    const formik= useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    const handleSubmit = async (values: IUserLoginSubmit) => {
        axiosRequest(HttpMethods.POST, `${apiRoutes.AUTH}/login`, "Login", values).then(response => {
            try {
                localStorage.setItem("user", JSON.stringify(response.data));
                const user = JSON.parse(localStorage.getItem("user") || '{}');
                if (user?.roles) {
                    setCurrentUser(user);
                    setShowModeratorPage(user.roles.includes("ROLE_MODERATOR"));
                    setShowAdminPage(user.roles.includes("ROLE_ADMIN"));
                    setAuthMessage("");
                }
            } catch (error) {
                console.error("Error parsing JSON string:", error);
            }
            navigate(`/`);
        }).catch(error => {
            handleRequestError(error, "Login");
        });
    }

    return (
    <div className="col-md-12">
        <HelmetComponent
            title="Login"
        />
        <div className="card card-container">
            <p style={{textAlign: 'center', width: '100%', fontSize: '2rem'}}>User Login</p>
            <img
                src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                alt="profile-img"
                className="profile-img-card"
            />
            <Form noValidate onSubmit={formik.handleSubmit}>

                <Form.Group controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        {...formik.getFieldProps('username')}
                        isInvalid={Boolean(formik.touched.username && formik.errors.username)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.username}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formOldPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        {...formik.getFieldProps('password')}
                        isInvalid={Boolean(formik.touched.password && formik.errors.password)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.password}
                    </Form.Control.Feedback>
                </Form.Group>

                <br/>
                <div className="text-center">
                    <Button type="submit">Login</Button>
                </div>
            </Form>

            <div className="d-flex mt-4 justify-content-center">
                <Link to="/register" className="btn btn-secondary me-3">Sign Up</Link>
                <Link to="/help" className="btn btn-secondary">Help Page</Link>
            </div>
        </div>
    </div>
    );
};

export default Login;
